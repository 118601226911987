import { gql } from '@apollo/client'

export const DELETE_USERS = gql`
  mutation deleteUsers($ids: [Int]) {
    deleteUsers(ids: $ids)
  }
`

export const TOGGLE_LOCK_USER = gql`
  mutation toggleLockUser($id: Int) {
    toggleLockUser(id: $id) {
      id
    }
  }
`

export const TOGGLE_CLIENT_VEGACE_USER = gql`
  mutation toggleClientVegaCEUser($id: Int) {
    toggleClientVegaCEUser(id: $id) {
      id
    }
  }
`

export const EDIT_USER = gql`
  mutation editUser($input: UserEditInput) {
    editUser(input: $input) {
      id
      email
      role
      company
      clientVegaCE
      locked
      TVs {
        id
        name
        ref
      }
    }
  }
`

export const EDIT_MY_PROFILE = gql`
  mutation editMyProfile($user: UserInput) {
    editMyProfile(user: $user) {
      id
      email
    }
  }
`

export const LOGIN = gql`
  mutation login($input: Login) {
    login(input: $input)
  }
`

export const SEND_RESET_PASSWORD_REQUEST = gql`
  mutation sendResetPasswordRequest($email: String!) {
    sendResetPasswordRequest(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`