import { gql } from '@apollo/client'

export const LIST_ALL_SURVEYS = gql`
  query {
    listAllSurveys {
      id
      title
      slug
      start_date
      end_date
      uniqueResponse
      answers {
        id
      }
      questions {
        id
        type
        title
        name
        isRequired
        placeHolder
        order
        colCount
        choicesOrder
        hasOther
        otherText
        maxSelectedChoices
        choices
        hasNone
        hasSelectAll
        maxSelectedChoices
        maximumRateDescription
        minRateDescription
      }
      owner {
        id
        company
      }
      author {
        id
        company
      }
    }
  }
`

export const GENERATE_SURVEY_JSON = gql`
  query generateSurveyJSON($surveySlug: String) {
    generateSurveyJSON(surveySlug: $surveySlug) {
      id
      title
      start_date
      end_date
      uniqueResponse
      slug
      questions {
        id
        type
        title
        name
        isRequired
        placeHolder
        order
        colCount
        choicesOrder
        hasOther
        otherText
        maxSelectedChoices
        choices
        hasNone
        hasSelectAll
        maxSelectedChoices
        maximumRateDescription
        minRateDescription
      }
    }
  }
`