import { useMutation } from '@apollo/client'
import { Button, Grid, Icon, InputLabel, FormControl, Link, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useReducer, useContext } from 'react'
import { EDIT_DOCUMENT } from '../graphql/document'
import { DropzoneArea } from 'material-ui-dropzone'
import useSnackbar from '../hooks/useSnackar'
import moment from 'moment'
import 'moment/locale/fr'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'

const initState = {
  document: {
    name: '',
    filename: '',
    start_date: '',
    end_date: null,
    url: '',
    file: null,
  },
}

function truncate(str, n) {
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_DOCUMENT':
      const { __typename, author, ...restDocument } = action.payload
      return {
        ...state,
        document: {
          ...state.document,
          ...restDocument,
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        document: {
          ...state.document,
          end_date: action.payload?.field === 'start_date' && moment(action.payload?.value).isAfter(moment(state.document?.end_date || moment())) ? null : state.document?.end_date,
          [action.payload?.field]: action.payload?.value,
        },
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
}))

const { useImperativeHandle } = React

const DocumentForm = React.forwardRef(({ initDocument, handleClose, listUsers, refetchDocuments, }, ref) => {
  const classes = useStyles()
  const { addSuccessSnackbar } = useSnackbar()
  const { authState } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initState, () => ({
    ...initState,
    document: {
      ...initState.document,
      ownerId: initDocument?.owner?.id || authState.id,
      authorId: initDocument?.author?.id,
    }
  }))
  const [editDocument, { loading: editLoading }] = useMutation(EDIT_DOCUMENT)
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  useImperativeHandle(ref, () => ({
    async handleSave() {
      await editDocument({
        variables: {
          input: {
            ...state?.document,
            owner: undefined,
            authorId: undefined,
          }
        }
      })
      await refetchDocuments()
      addSuccessSnackbar('Enregistré avec succès')
      if (!state?.document?.id) {
        handleClose()
      }
    }
  }))
  useEffect(() => {
    if (initDocument && initDocument.id) {
      dispatch({ type: 'INIT_DOCUMENT', payload: { ...initDocument, authorId: initDocument?.author?.id } })
    }
  }, [])
  return (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      <TextField className={classes.formElement}
        fullWidth
        required
        margin="dense"
        name="name"
        label="Nom"
        variant="outlined"
        autoComplete="off"
        value={state.document?.name}
        onChange={handleChange()}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField className={classes.formElement}
            fullWidth
            required
            id="datetime-local"
            type="datetime-local"
            label="Début d'affichage"
            variant="outlined"
            margin="dense"
            value={state.document?.start_date ? moment(state.document?.start_date).format('YYYY-MM-DD[T]HH:mm') : ''}
            name="start_date"
            onChange={handleChange()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField className={classes.formElement}
            fullWidth
            id="datetime-local"
            name="end_date"
            label="Fin d'affichage"
            type="datetime-local"
            variant="outlined"
            margin="dense"
            value={state.document?.end_date ? moment(state.document?.end_date).format('YYYY-MM-DD[T]HH:mm') : ''}
            onChange={handleChange()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {authState?.role === ENUM_USER_ROLE.ADMIN && (
          <Grid item xs={12}>
            <FormControl variant="outlined" required fullWidth className={classes.formElement}>
              <InputLabel id="select-owner">Client</InputLabel>
              <Select
                margin="dense"
                labelId="select-owner"
                labelWidth={55}
                value={state.document?.ownerId}
                name="ownerId"
                onChange={handleChange()}
              >
                {listUsers?.map(({ id, company }) => (
                  <MenuItem key={id} value={id}>{company}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      {
        <div style={{ margin: '16px auto' }}>
          {state.document?.url ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_URL}${state.document?.url}`}
                target="_blank"
                variant="contained"
                color="primary"
                component={Link}
                endIcon={<Icon>launch</Icon>}
              >
                Aperçu
              </Button>
              {`${state.document.authorId}` === `${authState.id}` && (
                <Button onClick={() => dispatch({ type: 'EDIT_FIELD', payload: { field: 'url', value: null } })}>Retirer ce document</Button>
              )}
            </div>
          ) : (
            <form encType="multipart/form-data">
              <InputLabel style={{ marginBottom: '16px' }}>Importation du document *</InputLabel>
              <DropzoneArea
                filesLimit={1}
                dropzoneText={"Faites glisser et déposez un document ici ou cliquez"}
                onChange={(files) => dispatch({ type: 'EDIT_FIELD', payload: { field: 'file', value: files[0] } })}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewChipProps={{ classes: { root: classes.previewChip } }}
                previewText="Fichier sélectionné"
                maxFileSize={10000000}
                getFileLimitExceedMessage={(filesLimit) => `Nombre maximum de fichiers atteint. Seulement ${filesLimit} autorisé(s)`}
                getFileAddedMessage={(filename) => `Fichier ${truncate(filename, 15)} ajouté.`}
                getFileRemovedMessage={(filename) => `Fichier ${truncate(filename, 15)} retiré.`}
                getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => `Fichier ${truncate(rejectedFile.name, 15)} rejeté. Fichiers autorisés : ${acceptedFiles.join(', ')}, taille inférieur à ${maxFileSize / 1000000} Mo`}
                alertSnackbarProps={{
                  autoHideDuration: 5000,
                }}
              />
            </form>
          )}
        </div>
      }
    </>
  )
})

export default DocumentForm