import { gql } from '@apollo/client'

export const DELETE_TVS = gql`
  mutation deleteTVs($ids: [Int]) {
    deleteTVs(ids: $ids)
  }
`

export const EDIT_TV = gql`
  mutation editTV($input: TVInput) {
    editTV(input: $input) {
      id
      ref
      name
      token
      owner {
        company
      }
    }
  }
`

export const REFRESH_TVS = gql`
  mutation {
    refreshTVs
  }
`