import { useMutation } from '@apollo/client'
import { Grid, makeStyles, TextField, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, TableRow, TableCell } from '@material-ui/core'
import React, { useContext, useEffect, useReducer } from 'react'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { EDIT_TV } from '../graphql/tv'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'
import useSnackbar from '../hooks/useSnackar'
import moment from 'moment'

const initState = {
  tv: {
    id: null,
    name: '',
    ref: '',
    token: '',
    CityId: '',
    medias: [],
  },
  showToken: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_TV':
      const { __typename, available, city, ...restTV } = action.payload
      return {
        ...state,
        tv: {
          ...state.tv,
          ...restTV,
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        tv: {
          ...state.tv,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'TOGGLE_SHOW_TOKEN':
      return {
        ...state,
        showToken: !state.showToken,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
}))

const { useImperativeHandle } = React

const TVForm = React.forwardRef(({ initTV, handleClose, refetchTVs, company, cities = [] }, ref) => {
  const classes = useStyles()
  const { addSuccessSnackbar } = useSnackbar()
  const { authState } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const filterMedias = ({start_date, end_date}) => moment(start_date).isSameOrBefore(moment()) && (!end_date || moment(end_date).isSameOrAfter(moment()))
  const [editTV, { loading: editLoading }] = useMutation(EDIT_TV)
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  useImperativeHandle(ref, () => ({
    async handleSave() {
      await editTV({
        variables: {
          input: {
            ...state?.tv,
            startActivity: moment(state.tv?.startActivity, 'HH:mm'),
            endActivity: moment(state.tv?.endActivity, 'HH:mm'),
            owner: undefined,
            token: authState.role === ENUM_USER_ROLE.ADMIN ? state.tv?.token : undefined,
            medias: undefined
          }
        }
      })
      await refetchTVs()
      addSuccessSnackbar('Enregistré avec succès')
      if (!state?.tv?.id) {
        handleClose()
      }
    }
  }))
  useEffect(() => {
    if (initTV && initTV.id) {
      dispatch({ type: 'INIT_TV', payload: { ...initTV, startActivity: moment(initTV.startActivity).format('HH:mm'), endActivity: moment(initTV.endActivity).format('HH:mm'), CityId: initTV?.city?.id || '' } })
    }
  }, [initTV])

  const handleClickShowToken = () => {
    dispatch({ type: 'TOGGLE_SHOW_TOKEN' })
  }

  const handleMouseDownToken = (event) => {
    event.preventDefault()
  }
  return (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      {authState.role === ENUM_USER_ROLE.ADMIN && (
        <TextField className={classes.formElement}
          fullWidth
          required
          margin="dense"
          name="ref"
          label="Référence VegaCE"
          variant="outlined"
          autoComplete="off"
          value={state.tv?.ref}
          onChange={handleChange()}
        />
      )}
      <TextField className={classes.formElement}
        fullWidth
        required
        margin="dense"
        name="name"
        label="Nom"
        variant="outlined"
        autoComplete="off"
        value={state.tv?.name}
        onChange={handleChange()}
      />
      {authState.role === ENUM_USER_ROLE.ADMIN && (
        <FormControl margin="dense" variant="outlined" fullWidth required className={classes.formElement}>
          <InputLabel id="select-localisation">Localisation</InputLabel>
          <Select
            labelId="select-localisation"
            labelWidth={100}
            value={`${state.tv?.CityId}`}
            onChange={handleChange()}
            name="CityId"
          >
            <MenuItem value={''}>Choisir une localisation</MenuItem>
            {cities.map(({ id, name }) => (
              <MenuItem key={id} value={`${id}`}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {authState.role === ENUM_USER_ROLE.ADMIN && (
        <TextField className={classes.formElement}
          fullWidth
          required
          margin="dense"
          name="token"
          label={state.tv?.id ? "Changer le token" : "Token"}
          helperText={'Ce token est utilisé pour identifier la TV, attention en le modifiant'}
          type={state.showToken ? 'text' : 'password'}
          variant="outlined"
          autoComplete="off"
          value={state.tv?.token}
          InputProps={{
            endAdornment: (
              <IconButton
                size='small'
                aria-label="toggle token visibility"
                onClick={handleClickShowToken}
                onMouseDown={handleMouseDownToken}
                edge="end"
              >
                {state.showToken ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            )
          }}
          onChange={handleChange()}
        />
      )}
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="time-start"
            label="Début de l'affichage"
            helperText={"Valable pour tous les jours, y compris le week-end"}
            type="time"
            variant="outlined"
            margin="dense"
            value={state.tv?.startActivity || ''}
            name="startActivity"
            onChange={handleChange()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="time-end"
            label="Fin de l'affichage"
            helperText={"Doit être supérieur au début de l'affichage"}
            type="time"
            variant="outlined"
            margin="dense"
            value={state.tv?.endActivity || ''}
            name="endActivity"
            onChange={handleChange()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      {(authState.role === ENUM_USER_ROLE.ADMIN && company) && (
        <Grid container style={{ marginTop: '16px' }} spacing={2}>
          <Grid item>
            <Typography>Cette TV se trouve chez : </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 'bold' }}>{company}</Typography>
          </Grid>
        </Grid>
      )}
      {state?.tv?.medias?.length > 0 &&
        <Grid container style={{ marginTop: '16px' }} spacing={2}>
          <Grid item>
            <Typography>Médias affichés sur cette TV : </Typography>
            {initTV?.medias?.filter(filterMedias)?.map(({ id, name, start_date, end_date, type }) => (
              <TableRow
                key={id}
              >
                <TableCell align="left" style={{ fontWeight: 'bold'}}>{name}</TableCell>
                <TableCell align="left"> Du {moment(start_date).locale('fr').format('LL')}</TableCell>
                {end_date ? 
                  <TableCell align="left"> Au {moment(end_date).locale('fr').format('LL')}</TableCell>
                : 
                  <TableCell align="left">Aucune date de fin définie</TableCell>
                }
                <TableCell align="left">{type}</TableCell>
              </TableRow>
            ))}
          </Grid>
        </Grid>
      }
    </>
  )
})

export default TVForm