import React, { useReducer } from 'react'
import { Typography } from '@material-ui/core'
import { Model, StylesManager, Survey } from 'surveyjs-react'
import { useQuery, useMutation } from '@apollo/client'
import { ANSWER_SURVEY, ENUM_SURVEY_QUESTION_INPUT_TYPE, ENUM_SURVEY_QUESTION_TYPE, GENERATE_SURVEY_JSON } from '../graphql/survey'
import moment from 'moment'
import useSnackbar from '../hooks/useSnackar'

const initState = {
  survey: {
    title: '',
    questions: [],
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'GENERATE_SURVEY_JSON': {
      return {
        ...state,
        survey: {
          ...state.survey,
          ...action.payload,
        },
      }
    }
    default:
      throw new Error('Unhandled action type')
  }
}

const sortByOrder = (a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0
const CHOICES_SEPARATOR = ';'

function trimTrailingChars(s, charToTrim) {
  var regExp = new RegExp(charToTrim + "+$");
  var result = s.replace(regExp, "");

  return result;
}

export default function PublicSurvey({ slug }) {

  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const { addSuccessSnackbar } = useSnackbar()

  useQuery(GENERATE_SURVEY_JSON, {
    onCompleted: (data) => {
      dispatch({
        type: 'GENERATE_SURVEY_JSON', payload: {
          ...data.generateSurveyJSON,
          title: data.generateSurveyJSON?.title,
          questions: data?.generateSurveyJSON?.questions?.sort(sortByOrder)?.filter(({ name }) => name)?.map((item) => {
            if ([ENUM_SURVEY_QUESTION_TYPE.radiogroup, ENUM_SURVEY_QUESTION_TYPE.dropdown, ENUM_SURVEY_QUESTION_TYPE.checkbox].includes(item.type)) {
              return {
                ...item,
                // Clear trailing separators or spaces and remove empty choices (if several separators in a row)
                choices: trimTrailingChars(item.choices.trim(), CHOICES_SEPARATOR).split(CHOICES_SEPARATOR)?.map((choice) => choice.trim())?.filter((v) => v !== '')
              }
            }
            if ([ENUM_SURVEY_QUESTION_TYPE.text].includes(item.type)) {
              if (item.inputType === ENUM_SURVEY_QUESTION_INPUT_TYPE.email) {
                return {
                  ...item,
                  validators: [
                    {
                      type: 'email',
                    },
                  ],
                }
              }
            }
            return item
          }) || [],
        }
      })
    },
    variables: {
      surveySlug: slug,
    },
  })

  const [answerSurvey] = useMutation(ANSWER_SURVEY)

  const json = {
    title: state?.survey?.title, //state?.survey?.title,
    questions: state?.survey?.questions,
    cookieName: state?.survey?.uniqueResponse ? `survey-${state?.survey?.slug}` : undefined,
  }

  StylesManager.applyTheme("modern")
  const survey = new Model(json)
  // survey.mode = 'display'
  survey.locale = 'fr'
  survey
    .onComplete
    .add(function (result) {
      const input = {
        surveyId: state.survey.id,
        answers: JSON.stringify(result.data, null, 3),
      }
      answerSurvey({
        variables: {
          input,
        },
      })
        .then((data) => {
          if (data) {
            addSuccessSnackbar('Réponse(s) enregistrée(s)')
          }
        })
    });

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', padding: '30px' }}>

      {moment().isBetween(state.survey.start_date, state.survey.end_date) ?
        (<>
          <Typography variant="h2">{state.survey.title}</Typography>
          <Survey model={survey} />
        </>)
        : moment().isBefore(state.survey.start_date) ?
          (<>
            <Typography style={{ fontWeight: 'bold', color: 'red' }}>Ce sondage débutera le {moment(state.survey.start_date).format('D MMM YYYY [à] HH:mm')}</Typography>
          </>)
          : moment().isAfter(state.survey.end_date) &&
          (<>
            <Typography style={{ fontWeight: 'bold', color: 'red' }}>Ce sondage n'est plus disponible</Typography>
          </>)
      }

    </div>
  )
}