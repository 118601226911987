import React, { useContext, useEffect, useState } from 'react'
import { Fade, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import Icon from '@material-ui/core/Icon'
import { lightBlue, orange } from '@material-ui/core/colors'
import { useQuery, useSubscription } from '@apollo/client'
import { GET_WEATHER_WIDGET, ON_WEATHER_UPDATE } from '../graphql/widget'
import { AuthContext } from '../AuthContext'
import DigitalClock from '../components/DigitalClock'
// import Clock from 'react-clock'

const getWeatherIllustration = (icon, size) => {
  const realSize = size === 'medium' ? '@2x' : size === 'large' ? '@4x' : ''
  return icon ? `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/public/images/weather/${icon}${realSize}.png` : ''
}

const windDirection = [
  {
    min: 337.5, max: 360, value: 'N',
  },
  {
    min: 0, max: 22.5, value: 'N',
  },
  {
    min: 22.5, max: 67.5, value: 'NE',
  },
  {
    min: 67.5, max: 112.5, value: 'E',
  },
  {
    min: 112.5, max: 157.5, value: 'SE',
  },
  {
    min: 157.5, max: 202.5, value: 'S',
  },
  {
    min: 202.5, max: 247.5, value: 'SO',
  },
  {
    min: 247.5, max: 252.5, value: 'O',
  },
  {
    min: 252.5, max: 337.5, value: 'NO',
  },
]

const getWindDirection = (deg) => {
  return windDirection.find(({ min, max }) => deg >= min && deg < max)?.value || '?'
}

const msToKmh = (ms) => ms * 3.6

const bOverW = false

const toFirstUppercase = (string) => `${string[0]?.toUpperCase()}${string.slice(1)}`

const InfoItem = ({ label, value, children, style, ...props }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', ...style }} {...props}>
    <Typography variant="subtitle1" style={value ? { marginRight: '15px' } : {}}>{label}</Typography>
    {value && <Typography variant="h5" style={{ marginTop: '2px' }}>{value}</Typography>}
    {children}
  </div>
)

function DayForecast({ forecast }) {
  return (
    <div style={{ color: bOverW ? 'black' : 'white', padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4">{moment(forecast?.dt * 1000).format('ddd').toUpperCase()}</Typography>
        <div style={{ width: '190px', height: '125px', backgroundImage: `url("${getWeatherIllustration(forecast?.weather?.find((_, index) => index === 0)?.icon || '')}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ShortTempInfo min={Math.round(forecast?.temp?.min)} max={Math.round(forecast?.temp?.max)} />
          <WindInfo speed={Math.round(msToKmh(forecast?.wind_speed))} deg={forecast?.wind_deg} />
        </div>
      </div>
    </div>
  )
}

function CurrentWeather({ current, forecast, localisation }) {
  return (
    <div style={{ height: '100%', color: bOverW ? 'black' : 'white', padding: '15px 0 15px 15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-around' }}>
      <div style={{ height: '100%', display: 'flex', flex: 1, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ height: '100%', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'baseline', justifyContent: 'space-around', paddingLeft: '15px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon className='fas fa-map-marker-alt' style={{ marginRight: '10px' }} />
            <Typography variant="h5">{toFirstUppercase(localisation)}</Typography>
          </div>
          <TempInfo current={Math.round(current?.temp)} feelsLike={Math.round(current?.feels_like)} min={Math.round(forecast?.temp?.min)} max={Math.round(forecast?.temp?.max)} />
          <WindInfo speed={Math.round(msToKmh(forecast?.wind_speed))} deg={forecast?.wind_deg} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <UVInfo uvi={Math.round(forecast?.uvi)} style={{ marginRight: '10px' }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RainInfo pop={forecast?.pop} style={{ marginRight: '10px' }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <HumidityInfo humidity={forecast?.humidity} style={{ marginRight: '10px' }} />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img alt='' style={{ maxHeight: '350px', maxWidth: '350px', width: '100%' }} src={getWeatherIllustration(forecast?.weather?.find((_, index) => index === 0)?.icon || '', 'large')} />
          <Typography variant="h3">{toFirstUppercase(forecast?.weather?.find((_, index) => index === 0)?.description || '')}</Typography>
        </div>
      </div>
    </div>
  )
}

function HourForecast({ forecast }) {
  return (
    <div style={{ borderRadius: '10px', color: bOverW ? 'black' : 'white', backgroundColor: 'rgb(56, 127, 179)', padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4">{moment(forecast?.dt * 1000).format('HH')}h</Typography>
        <div style={{ width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '100px', height: '100px', backgroundImage: `url("${getWeatherIllustration(forecast?.weather?.find((_, index) => index === 0)?.icon || '', 'medium')}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} />
        </div>
        <Typography variant="h5">{Math.round(forecast?.temp)}°</Typography>
      </div>
    </div>
  )
}

const TempInfo = ({ current, min, max, feelsLike, style, ...props }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...style }} {...props}>
      <Icon style={{ fontSize: 50 }} className="fas fa-thermometer-half" />
      <Typography variant="h2">{current}°</Typography>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h5" style={{ color: lightBlue[300], marginRight: '10px' }}>{min}°</Typography>
          <Typography variant="h5" style={{ color: orange[800] }}>{max}°</Typography>
        </div>
        <Typography variant="h5">Ress. {feelsLike}°</Typography>
      </div>
    </div>
  )
}

const ShortTempInfo = ({ min, max, style, ...props }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...style }} {...props}>
      <Typography variant="h5" style={{ color: lightBlue[300] }}>{min}°</Typography>
      <Typography variant="h5" style={{ margin: '0 5px' }}>/</Typography>
      <Typography variant="h5" style={{ color: orange[800] }}>{max}°</Typography>
    </div>
  )
}

const WindInfo = ({ speed, deg, ...props }) => {
  return (
    <InfoItem label={<Icon style={{ fontSize: 30 }} className="fas fa-wind" />} value={`${speed} km/h (${getWindDirection(deg)})`} {...props}>
      {/* <NavigationIcon style={{ marginLeft: '5px', marginTop: '10px', transform: `rotate(${deg || 0}deg)` }} /> */}
    </InfoItem>
  )
}

const UVInfo = ({ uvi, ...props }) => {
  return (
    <InfoItem label={<Icon style={{ fontSize: 30 }} className="fas fa-sun" />} value={`UV ${uvi || '?'}`} {...props} />
  )
}

// const CloudInfo = ({ clouds, ...props }) => {
//   return (
//     <InfoItem label={<Icon className="fas fa-cloud" style={{ width: '30px' }} />} value={`${clouds || 0} %`} {...props} />
//   )
// }

const HumidityInfo = ({ humidity, ...props }) => {
  return (
    <InfoItem label={<Icon className="fas fa-tint" style={{ width: '20px' }} />} value={`${humidity || 0} %`} {...props} />
  )
}

const RainInfo = ({ pop, ...props }) => {
  return (
    <InfoItem label={<Icon className="fas fa-cloud-showers-heavy" style={{ width: '24px' }} />} value={`${pop || 0} %`} {...props} />
  )
}

const hourlyFilter = ({ dt }) => parseInt(moment(dt * 1000).format('H')) >= parseInt(moment().format('H')) || parseInt(moment(dt * 1000).format('D')) >= parseInt(moment().format('D'))

export default function WeatherWidget({ displayed, timeout }) {
  const { data, loading } = useQuery(GET_WEATHER_WIDGET)
  const [weatherData, setWeatherData] = useState({})
  const { authState } = useContext(AuthContext)
  useSubscription(ON_WEATHER_UPDATE, {
    onSubscriptionData: (data) => {
      const weather = data?.subscriptionData?.data?.onWeatherUpdate
      if (weather) {
        const parsedData = JSON.parse(weather?.data || '{}')
        const { ephemeride } = parsedData
        setWeatherData({
          data: parsedData,
          ephemeride,
          lastUpdate: moment(weather?.updatedAt),
        })
      }
    }
  })
  useEffect(() => {
    const parsedData = JSON.parse(data?.getWidgetByName?.widget?.data || '{}')
    const { ephemeride } = parsedData
    setWeatherData({
      data: parsedData,
      ephemeride,
      lastUpdate: moment(data?.getWidgetByName?.widget?.updatedAt),
    })
  }, [authState, data])
  const [clockValue, setClockValue] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(
      () => setClockValue(new Date()),
      1000
    );

    return () => {
      clearInterval(interval);
    }
  }, []);

  const ephemerideList = weatherData?.ephemeride?.map((item, index) => 
    <Typography variant="h4" align="right" key={index}>{item}</Typography>
  )

  return (
    <Fade in={displayed} timeout={timeout}>
      <div className='widget' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', maxWidth: '100%', width: '100%', color: 'white', margin: 0, backgroundColor: 'rgb(0,92,157)', /*backgroundImage: `linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 100%, rgba(0,212,255,0) 100%), url("${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/public/images/clouds.jpg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'*/ }}>
        <div style={{ position: 'relative' }}>
          <img alt='' style={{ maxWidth: '100%' }} src={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/public/images/meteo_bandeau.png`} />
          <div style={{ position: 'absolute', top: 50, left: 30 }}>
            <Typography variant="h2">
              <DigitalClock value={clockValue} />
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
              <Typography variant="h3">{toFirstUppercase(moment().format('dddd'))}</Typography>
              <Typography variant="h6" style={{ marginLeft: '15px', marginBottom: '3px' }}>{toFirstUppercase(moment().format('DD MMMM YYYY'))}</Typography>
            </div>
          </div>
        </div>
        {!loading && (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', height: '100%', flex: 1, margin: 0 }}>
            <div style={{ height: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-around' }}>
                <div>
                  <CurrentWeather
                    lastUpdate={weatherData?.lastUpdate || moment()}
                    current={weatherData?.data?.[authState?.cityId]?.current}
                    localisation={authState?.cityName || '?'}
                    forecast={weatherData?.data?.[authState?.cityId]?.daily?.find((_, index) => index === 0) || {}}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                  <Grid container spacing={2} style={{ padding: '0 8px' }}>
                    {weatherData?.data?.[authState?.cityId]?.hourly?.filter(hourlyFilter).filter((_, index) => index < 18).map((item, index) => index % 3 === 0 && (
                      <Grid key={item.dt} item xs={2}>
                        <HourForecast forecast={item} />
                      </Grid>
                    ))}
                  </Grid>
                  <div style={{ margin: '15px 0' }}>
                    <hr />
                  </div>
                  <Grid container>
                    {weatherData?.data?.[authState?.cityId]?.daily?.filter((_, index) => index > 0 && index < 4).map((item) => (
                      <Grid key={item.dt} item xs={4}>
                        <DayForecast forecast={item} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
            <div style={{ position: 'absolute', top: 150, right: 30 }}>
              <Typography variant="subtitle1" style={{ fontStyle: 'italic' }}>Aujourd'hui, nous fêtons les :</Typography>
              {ephemerideList}
            </div>
            <div style={{ position: 'absolute', bottom: 5, right: 30, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Typography style={{ marginBottom: '10px', fontSize: '6px' }} variant="body2" align="center">Actualisé {moment(weatherData?.lastUpdate)?.format('[le] D MMM [à] HH:mm')}</Typography>
            </div>
          </div>
        )}
      </div>
    </Fade>
  )
}