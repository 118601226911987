import { gql } from '@apollo/client'

export const EDIT_JOB = gql`
  mutation editJob($input: JobInput) {
    editJob(input: $input) {
      id
      name
      description
      enabled
      cron
    }
  }
`

export const EXECUTE_JOBS = gql`
  mutation {
    executeJobs
  }
`