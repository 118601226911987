import React, { useRef, useState } from 'react'
import UserTable from '../components/UserTable'
import { useQuery, useMutation } from '@apollo/client'
import { DELETE_USERS, LIST_USERS, TOGGLE_CLIENT_VEGACE_USER, TOGGLE_LOCK_USER } from '../graphql/user'
import { Button } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import UserForm from '../forms/UserForm'
import { LIST_ALL_TVS } from '../graphql/tv'
import { LIST_ALL_CITIES } from '../graphql/city'
import useSnackbar from '../hooks/useSnackar'

export default function Users() {
  const { addSuccessSnackbar } = useSnackbar()
  const { loading, data, refetch: refetchUsers } = useQuery(LIST_USERS)
  const { loading: tvLoading, data: listTVs, refetch: refetchTVs } = useQuery(LIST_ALL_TVS)
  const { loading: cityLoading, data: listCities } = useQuery(LIST_ALL_CITIES)
  const [deleteUsers, { loading: deleteLoading }] = useMutation(DELETE_USERS, {
    onCompleted: (data) => {
      const { deleteUsers } = data || {}
      if (deleteUsers > 0) {
        refetchUsers()
      }
    }
  })
  const [toggleLockUser, { loading: toggleLockLoading }] = useMutation(TOGGLE_LOCK_USER, {
    onCompleted: (data) => {
      const { toggleLockUser } = data || {}
      if (toggleLockUser.id) {
        addSuccessSnackbar('Enregistré avec succès')
        refetchUsers()
      }
    }
  })
  const [toggleClientVegaCEUser, { loading: toggleClientVegaCELoading }] = useMutation(TOGGLE_CLIENT_VEGACE_USER, {
    onCompleted: (data) => {
      const { toggleClientVegaCEUser } = data || {}
      if (toggleClientVegaCEUser.id) {
        addSuccessSnackbar('Enregistré avec succès')
        refetchUsers()
      }
    }
  })

  const [open, setOpen] = useState(false)
  const [editUser, setEditUser] = useState(false)

  const handleRowClick = (userObj) => {
    setEditUser(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditUser(false)
  }

  const userFormRef = useRef()

  const handleSave = () => {
    userFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )

  return (
    <>
      <UserTable toggleClientVegaCEUser={toggleClientVegaCEUser} handleRowClick={handleRowClick} toggleLockUser={toggleLockUser} deleteUsers={deleteUsers} loading={loading || cityLoading || tvLoading || deleteLoading || toggleClientVegaCELoading || toggleLockLoading} rows={data?.listUsers || []} />
      <CustomModal open={open} handleClose={handleClose} actions={actions} title={editUser ? "Edition d'un utilisateur" : "Ajout d'un utilisateur"}>
        <UserForm ref={userFormRef} listTVs={listTVs?.listAllTVs || []} cities={listCities?.listAllCities || []} initUser={editUser} handleClose={handleClose} refetchUsers={refetchUsers} refetchTVs={refetchTVs} />
      </CustomModal>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Ajouter</Button>
    </>
  )
}