import React, { useCallback, useEffect, useState } from 'react'
import tmdbGenres from './tmdbGenres.json'
import { Chip, Fade, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import { useQuery, useSubscription } from '@apollo/client'
import { GET_NOW_PLAYING_WIDGET, GET_UPCOMING_WIDGET, ON_NOW_PLAYING_UPDATE, ON_UPCOMING_UPDATE } from '../graphql/widget'

/*
"poster_sizes": [
    "w92",
    "w154",
    "w185",
    "w342",
    "w500",
    "w780",
    "original"
],
*/

function decode_utf8(s) {
  return decodeURIComponent(escape(s));
}

const getMoviePicture = (size, filename) => `https://image.tmdb.org/t/p/${size || 'original'}${filename}`

const sortByReleaseDateThenVote = (a, b) => a.release_date > b.release_date ? -1 : a.release_date < b.release_date ? 1 : a.vote_average > b.vote_average ? 1 : a.vote_average < b.vote_average ? -1 : 0

const POSTER_DISPLAY_TIME = 5 // seconds

const useStyles = makeStyles(() => ({
  chip: {
    color: 'white',
    borderColor: 'white',
  }
}))

export default function CinemaWidget({ displayed, duration = 45, timeout, service }) {
  const classes = useStyles()
  const { data, loading } = useQuery(service === 'nowPlaying' ? GET_NOW_PLAYING_WIDGET : GET_UPCOMING_WIDGET)
  const [currentIndex, setCurrentIndex] = useState(0)
  const maxIndex = Math.ceil(duration / POSTER_DISPLAY_TIME)
  const [moviesData, setMoviesData] = useState({})
  const incrementIndex = useCallback(() => {
    setCurrentIndex(prev => prev + 1 >= maxIndex ? 0 : prev + 1)
  }, [maxIndex])
  useSubscription(service === 'nowPlaying' ? ON_NOW_PLAYING_UPDATE : ON_UPCOMING_UPDATE, {
    onSubscriptionData: (data) => {
      const tmdb = data?.subscriptionData?.data[service === 'nowPlaying' ? 'onNowPlayingUpdate' : 'onUpcomingUpdate']
      if (tmdb) {
        setMoviesData({
          data: JSON.parse(tmdb?.data || '[]'),
          lastUpdate: moment(tmdb?.updatedAt),
        })
      }
    }
  })
  useEffect(() => {
    setMoviesData({
      data: JSON.parse(data?.getWidgetByName?.widget?.data || '[]'),
      lastUpdate: moment(data?.getWidgetByName?.widget?.updatedAt),
    })
  }, [data])
  useEffect(() => {
    let interval
    if (displayed) {
      setCurrentIndex(0)
      interval = setInterval(() => {
        incrementIndex()
      }, (POSTER_DISPLAY_TIME + 0.5) * 1000)
    } else {
      if (interval) {
        clearInterval(interval)
        interval = 0
      }
    }
    return () => {
      clearInterval(interval)
      interval = 0
    }
  }, [displayed, incrementIndex])
  return (
    <Fade in={displayed} timeout={timeout}>
      <div className='widget' style={{ display: 'flex', color: 'white', flexDirection: 'column', backgroundColor: 'black', alignItems: 'center', justifyContent: 'flex-start', maxHeight: '100%', height: '100%', overflow: 'hidden', width: '100%', margin: 0 }}>
        <div style={{ margin: '0', width: '100%' }}>
          <img alt='' style={{ width: '100%' }} src={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/public/images/${service === 'nowPlaying' ? 'actuellement_en_salle' : 'bientot_en_salle_02'}_bandeau.png`} />
        </div>
        <div style={{ width: '100%', flex: 1 }}>
          <div style={{ position: 'relative', width: '100%', height: '100%', margin: 0 }} justify="space-between">
            {loading ? 'Chargement...' : moviesData?.data?.sort(sortByReleaseDateThenVote).filter(({ poster_path, overview, adult }) => !adult && Boolean(poster_path) && Boolean(overview)).filter((_, index) => index < maxIndex).map((movie, index) => (
              <Fade in={displayed && index === currentIndex} timeout={timeout}>
                <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 40, height: '100%' }}>
                  <div style={{ display: 'flex', height: '95%', flex: 1, flexDirection: 'column', justifyContent: "space-between", marginBottom: '30px' }}>
                    <div style={{ position: 'relative', margin: '0 auto', height: '100%', width: '100%', textAlign: 'center', display: 'table-cell' }}>
                      <img alt='' src={getMoviePicture('original', movie.poster_path)} style={{ border: '5px solid white', padding: '5px', position: 'absolute', transform: 'translate(-50%,0)', height: '100%', width: 'auto', zIndex: 1 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '50px', marginTop: '35px' }}>
                      <Typography variant="h4">{decode_utf8(movie.title)}</Typography>
                      {movie.genre_ids.map((id) => tmdbGenres.find(({ id: idGenre }) => idGenre === id)?.name).map((genre) => (
                        <Chip size='small' className={classes.chip} key={genre} style={{ marginLeft: '10px' }} variant='outlined' label={genre} />
                      ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '50px', marginTop: '5px' }}>
                      <Typography variant="subtitle1">Sortie : {moment(movie.release_date, 'YYYY-MM-DD').format('D MMM YYYY')}</Typography>
                      <span style={{ margin: '0 5px' }}>|</span>
                      <Typography variant="subtitle1">{movie.vote_average ? `Note : ${movie.vote_average}/10` : <i>Pas de note</i>}</Typography>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
        <div style={{ position: 'absolute', bottom: 5, right: 30, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <img style={{ height: '6px', marginBottom: '5px' }} src={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/public/images/logo_tmdb.svg`} alt="TMDB logo" />
          <Typography style={{ marginBottom: '10px', fontSize: '6px' }} variant="body2" align="center">Actualisé {moment(moviesData?.lastUpdate)?.format('[le] D MMM [à] HH:mm')}</Typography>
        </div>
      </div>
    </Fade >
  )
}