import React, { useContext, useRef, useState } from 'react'
import CityTable from '../components/CityTable'
import { useQuery, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import CityForm from '../forms/CityForm'
import { DELETE_CITIES, LIST_ALL_CITIES } from '../graphql/city'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'

export default function Cities() {
  const { authState } = useContext(AuthContext)
  const { loading: cityLoading, data: listCities, refetch: refetchCities } = useQuery(LIST_ALL_CITIES)
  const [deleteCities, { loading: deleteLoading }] = useMutation(DELETE_CITIES, {
    onCompleted: (data) => {
      const { deleteCities } = data || {}
      if (deleteCities > 0) {
        refetchCities()
      }
    }
  })

  const [open, setOpen] = useState(false)
  const [editCity, setEditCity] = useState(false)

  const handleRowClick = (userObj) => {
    setEditCity(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditCity(false)
  }

  const cityFormRef = useRef()

  const handleSave = () => {
    cityFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )

  return (
    <>
      <CityTable handleRowClick={handleRowClick} deleteCities={deleteCities} loading={cityLoading || deleteLoading} rows={listCities?.listAllCities || []} />
      <CustomModal open={open} handleClose={handleClose} actions={actions} title={editCity ? "Edition d'une localisation" : "Ajout d'une localisation"}>
        <CityForm ref={cityFormRef} listCities={listCities?.listAllCities || []} initCity={editCity} handleClose={handleClose} refetchCities={refetchCities} />
      </CustomModal>
      {authState.role === ENUM_USER_ROLE.ADMIN && <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Ajouter</Button>}
    </>
  )
}