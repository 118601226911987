import { gql } from '@apollo/client'

export const LIST_USERS = gql`
  query {
    listUsers {
      id
      company
      email
      role
      locked
      clientVegaCE
      city {
        id
        name
      }
      TVs {
        id
        name
      }
    }
  }
`

export const GET_MY_PROFILE = gql`
  query {
    getMyProfile {
      id
      email
    }
  }
`