import { GET_MY_PROFILE, LIST_USERS } from './queries'
import { DELETE_USERS, EDIT_MY_PROFILE, EDIT_USER, LOGIN, RESET_PASSWORD, SEND_RESET_PASSWORD_REQUEST, TOGGLE_CLIENT_VEGACE_USER, TOGGLE_LOCK_USER } from './mutations'

const ENUM_USER_ROLE = {
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  TV: 'TV',
}

export { DELETE_USERS, EDIT_MY_PROFILE, EDIT_USER, ENUM_USER_ROLE, GET_MY_PROFILE, LIST_USERS, LOGIN, RESET_PASSWORD, SEND_RESET_PASSWORD_REQUEST, TOGGLE_CLIENT_VEGACE_USER, TOGGLE_LOCK_USER }
