import { gql } from '@apollo/client'

export const LIST_ALL_JOBS = gql`
  query {
    listAllJobs {
      id
      name
      description
      enabled
      cron
    }
  }
`