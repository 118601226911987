import { ApolloClient, InMemoryCache, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_PROTOCOL || 'http'}://${process.env.REACT_APP_API_BASE_URL}/graphql`,
  credentials: 'same-origin',
})

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_PROTOCOL || 'ws'}://${process.env.REACT_APP_API_BASE_URL}/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      credentials: 'same-origin',
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  // uri: `http://${baseApiUrl}/graphql`,
  link: splitLink,
  // connectToDevTools: true,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

export default client
