import React, { useContext, useRef, useState } from 'react'
import JobTable from '../components/JobTable'
import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import JobForm from '../forms/JobForm'
import { EXECUTE_JOBS, LIST_ALL_JOBS } from '../graphql/job'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'
import RefreshIcon from '@material-ui/icons/Refresh'

export default function Jobs() {
  const { authState } = useContext(AuthContext)
  const { loading: jobLoading, data: listJobs, refetch: refetchJobs } = useQuery(LIST_ALL_JOBS)
  const [refreshJobs, { loading: execJobsLoading }] = useMutation(EXECUTE_JOBS)

  const [open, setOpen] = useState(false)
  const [editJob, setEditJob] = useState(false)

  const handleRowClick = (userObj) => {
    setEditJob(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditJob(false)
  }

  const jobFormRef = useRef()

  const handleSave = () => {
    jobFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )

  return (
    <>
      <JobTable handleRowClick={handleRowClick} loading={jobLoading || execJobsLoading} rows={listJobs?.listAllJobs || []} />
      <CustomModal open={open} handleClose={handleClose} actions={actions} title={editJob ? "Edition d'un job" : "Ajout d'un job"}>
        <JobForm ref={jobFormRef} listJobs={listJobs?.listAllJobs || []} initJob={editJob} handleClose={handleClose} refetchJobs={refetchJobs} />
      </CustomModal>
      {authState.role === ENUM_USER_ROLE.ADMIN && <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Ajouter</Button>}
      {authState.role === ENUM_USER_ROLE.ADMIN && <Button variant="contained" color="primary" startIcon={<RefreshIcon />} style={{ marginLeft: '16px' }} onClick={() => refreshJobs()}>Forcer l'exécution des jobs</Button>}
    </>
  )
}