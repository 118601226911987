import { gql } from '@apollo/client'

export const DELETE_DOCUMENTS = gql`
  mutation deleteDocuments($ids: [Int]) {
    deleteDocuments(ids: $ids)
  }
`

export const EDIT_DOCUMENT = gql`
  mutation editDocument($input: DocumentInput) {
    editDocument(input: $input) {
      id
      name
      filename
      start_date
      end_date
      url
    }
  }
`