import { GENERATE_SURVEY_JSON, LIST_ALL_SURVEYS  } from './queries'
import { ANSWER_SURVEY, EXPORT_SURVEY,  DELETE_SURVEYS, EDIT_SURVEY } from './mutations'

const ENUM_SURVEY_QUESTION_TYPE = {
  text: 'text',
  radiogroup: 'radiogroup',
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  rating: 'rating',
}

const ENUM_SURVEY_CHOICES_ORDER = {
  none: 'Par défaut',
  asc: 'A-Z',
  desc: 'Z-A',
  random: 'Aléatoire',
}

const ENUM_SURVEY_QUESTION_INPUT_TYPE = {
  color: 'color',
  date: 'date',
  // datetime: 'datetime',
  // datetimelocal: 'datetimelocal',
  email: 'email',
  // month: 'month',
  number: 'number',
  password: 'password',
  // range: 'range',
  // tel: 'tel',
  text: 'text',
  time: 'time',
  // url: 'url',
  // week: 'week',
}

export {
  ANSWER_SURVEY,
  DELETE_SURVEYS,
  EDIT_SURVEY,
  ENUM_SURVEY_CHOICES_ORDER,
  ENUM_SURVEY_QUESTION_INPUT_TYPE,
  ENUM_SURVEY_QUESTION_TYPE,
  EXPORT_SURVEY,
  LIST_ALL_SURVEYS,
  GENERATE_SURVEY_JSON,
}