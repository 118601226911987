import React, { useContext, useRef, useState } from 'react'
import TVTable from '../components/TVTable'
import { useQuery, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import TVForm from '../forms/TVForm'
import { DELETE_TVS, LIST_ALL_TVS, REFRESH_TVS } from '../graphql/tv'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'
import { LIST_ALL_CITIES } from '../graphql/city'
import useSnackbar from '../hooks/useSnackar'
import { LIST_ALL_MEDIAS } from '../graphql/media'

export default function TVs() {
  const { authState } = useContext(AuthContext)
  const { addSuccessSnackbar } = useSnackbar()
  const { loading: tvLoading, data: listTVs, refetch: refetchTVs } = useQuery(LIST_ALL_TVS)
  const { loading: mediasLoading, data: listMedias, refetch: refetchMedias } = useQuery(LIST_ALL_MEDIAS)
  const { loading: cityLoading, data: listCities } = useQuery(LIST_ALL_CITIES)
  const [deleteTVs, { loading: deleteLoading }] = useMutation(DELETE_TVS, {
    onCompleted: (data) => {
      const { deleteTVs } = data || {}
      if (deleteTVs > 0) {
        refetchTVs()
      }
    }
  })
  const [refreshTVs, { loading: refreshLoading }] = useMutation(REFRESH_TVS, {
    onCompleted: () => {
      addSuccessSnackbar('TVs en cours de mise à jour')
    }
  })

  const [open, setOpen] = useState(false)
  const [editTV, setEditTV] = useState(false)

  const handleRowClick = (userObj) => {
    setEditTV(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditTV(false)
  }

  const tvFormRef = useRef()

  const handleSave = () => {
    tvFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )
  return (
    <>
      <TVTable handleRowClick={handleRowClick} deleteTVs={deleteTVs} loading={tvLoading || deleteLoading || cityLoading || refreshLoading} rows={listTVs?.listAllTVs || []} />
      <CustomModal open={open} handleClose={handleClose} actions={actions} title={editTV ? "Edition d'une TV" : "Ajout d'une TV"}>
        <TVForm ref={tvFormRef} cities={listCities?.listAllCities || []} company={editTV?.owner?.company} listTVs={listTVs?.listAllTVs.map((item) => ({ ...item, CityId: item.CityId || '' })) || []} listMedias={listMedias?.listAllMedias || []} initTV={editTV} handleClose={handleClose} refetchTVs={refetchTVs} />
      </CustomModal>
      {authState.role === ENUM_USER_ROLE.ADMIN && <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Ajouter</Button>}
      {authState.role === ENUM_USER_ROLE.ADMIN && <Button variant="contained" color="primary" onClick={refreshTVs} style={{ marginLeft: '16px' }}>Rafraîchir les TVs</Button>}
    </>
  )
}