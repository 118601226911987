import { LIST_ALL_MEDIAS, LIST_TV_MEDIAS } from './queries'
import { DELETE_MEDIAS, DOWN_MEDIA_ORDER, EDIT_MEDIA, UP_MEDIA_ORDER } from './mutations'
import { ON_MEDIA_CHANGES } from './subscriptions'

const ENUM_MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  // TEMPLATE: 'TEMPLATE',
  WIDGET: 'WIDGET',
  QRCODE: 'QRCODE',
}

const ENUM_MEDIA_QRCODE_TYPE = {
  LINK: 'LINK',
  DOCUMENT: 'DOCUMENT',
  SURVEY: 'SURVEY',
}

const ENUM_MEDIA_QRCODE_TYPE_LABEL = {
  LINK: 'Lien',
  DOCUMENT: 'Document',
  SURVEY: 'Sondage',
}

const ENUM_WIDGETS = {
  WEATHER: 'Météo',
  CINEMA_NOW_PLAYING: 'Actuellement au cinéma',
  CINEMA_UPCOMING: 'Sorties à venir au cinéma',
}

export { DELETE_MEDIAS, DOWN_MEDIA_ORDER, EDIT_MEDIA, ENUM_MEDIA_QRCODE_TYPE, ENUM_MEDIA_QRCODE_TYPE_LABEL, ENUM_MEDIA_TYPE, ENUM_WIDGETS, LIST_ALL_MEDIAS, LIST_TV_MEDIAS, ON_MEDIA_CHANGES, UP_MEDIA_ORDER }