import React, { useContext } from 'react'
import TvIcon from '@material-ui/icons/Tv'
import GroupIcon from '@material-ui/icons/Group'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Card, CardContent, Grid, Icon, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LinkIcon from '@material-ui/icons/Link'
import { KPIS } from '../graphql/stats'
import { useQuery } from '@apollo/client'
import { Skeleton } from '@material-ui/lab'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'
import RoomIcon from '@material-ui/icons/Room'
import PollIcon from '@material-ui/icons/Poll'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  square: {
    color: ({ loading }) => loading ? undefined : theme.palette.primary.contrastText,
    backgroundColor: ({ loading }) => loading ? undefined : theme.palette.primary.main,
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}))

function MediaControlCard({ icon, text, count, to, loading }) {
  const classes = useStyles({ loading })
  return (
    <Card className={classes.root}>
      <Avatar variant="square" className={classes.square}>
        {loading ? (
          <Skeleton variant="rect" width={50} height={50} />
        ) : icon}
      </Avatar>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {loading ? <Skeleton variant="text" width={50} /> : count}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{ textDecoration: 'none', alignItems: 'center' }} component={to ? Link : undefined} to={to}>
            {loading ? (
              <Skeleton variant="text" width={150} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <span>{text}</span>
                <span style={{ marginLeft: '5px' }}>{to && (<LinkIcon />)}</span>
              </div>
            )}
          </Typography>
        </CardContent>
      </div>
    </Card>
  )
}

export default function Dashboard() {
  const { loading, data } = useQuery(KPIS)
  const { authState } = useContext(AuthContext)
  return (
    <Grid container spacing={2}>
      {authState.role === ENUM_USER_ROLE.ADMIN && (
        <Grid item xs={12} md={4}>
          <MediaControlCard icon={<GroupIcon style={{ fontSize: 50 }} />} text="Utilisateurs" loading={loading} count={data?.kpis?.find(({ name }) => name === "users")?.value} to={"/users"} />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <MediaControlCard icon={<TvIcon style={{ fontSize: 50 }} />} text="Totems" loading={loading} count={data?.kpis?.find(({ name }) => name === "totems")?.value} to={"/totems"} />
      </Grid>
      <Grid item xs={12} md={4}>
        <MediaControlCard icon={<PermMediaIcon style={{ fontSize: 50 }} />} text="Médias" loading={loading} count={data?.kpis?.find(({ name }) => name === "medias")?.value} to={"/medias"} />
      </Grid>
      <Grid item xs={12} md={4}>
        <MediaControlCard icon={<PollIcon style={{ fontSize: 50 }} />} text="Sondages" loading={loading} count={data?.kpis?.find(({ name }) => name === "surveys")?.value} to={"/sondages"} />
      </Grid>
      <Grid item xs={12} md={authState.role === ENUM_USER_ROLE.ADMIN ? 4 : 6}>
        <MediaControlCard icon={<PermMediaIcon style={{ fontSize: 50 }} />} text="Documents" loading={loading} count={data?.kpis?.find(({ name }) => name === "document")?.value} to={"/document"} />
      </Grid>
      {authState.role === ENUM_USER_ROLE.ADMIN && (
        <>
          <Grid item xs={12} md={4}>
            <MediaControlCard icon={<RoomIcon style={{ fontSize: 50 }} />} text="Localisations" loading={loading} count={data?.kpis?.find(({ name }) => name === "cities")?.value} to={"/cities"} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MediaControlCard icon={<Icon style={{ fontSize: 50, textAlign: 'center' }} className="fas fa-clipboard-list" />} text="Jobs automatisés" loading={loading} count={data?.kpis?.find(({ name }) => name === "jobs")?.value} to={"/jobs"} />
          </Grid>
        </>
      )}
    </Grid>
  )
}