import React, { useContext, useRef, useState } from 'react'
import MediaTable from '../components/MediaTable'
import { useQuery, useMutation } from '@apollo/client'
import { Button, Link, Icon } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import MediaForm from '../forms/MediaForm'
import { DELETE_MEDIAS, DOWN_MEDIA_ORDER, LIST_ALL_MEDIAS, LIST_TV_MEDIAS, UP_MEDIA_ORDER } from '../graphql/media'
import { LIST_ALL_TVS, LIST_USER_TVS } from '../graphql/tv'
import { LIST_USERS } from '../graphql/user'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'
import useSnackbar from '../hooks/useSnackar'
import { LIST_ALL_DOCUMENTS } from '../graphql/document'
import { LIST_ALL_SURVEYS } from '../graphql/survey'

export default function Medias() {
  const { authState } = useContext(AuthContext)
  const { addSuccessSnackbar } = useSnackbar()
  const { loading: userLoading, data: listUsers } = useQuery(LIST_USERS)
  const { loading: mediaLoading, data: listMedias, refetch: refetchMedias } = useQuery(LIST_ALL_MEDIAS)
  const { loading: tvMediasLoading, data: listTVMedias, refetch: refetchTVMedias } = useQuery(LIST_TV_MEDIAS)
  const { loading: tvLoading, data: listTVs } = useQuery(authState?.role === ENUM_USER_ROLE.ADMIN ? LIST_ALL_TVS : LIST_USER_TVS)
  const { loading: documentLoading, data: listDocuments } = useQuery(LIST_ALL_DOCUMENTS)
  const { loading: surveyLoading, data: listSurveys } = useQuery(LIST_ALL_SURVEYS)
  const [deleteMedias, { loading: deleteLoading }] = useMutation(DELETE_MEDIAS, {
    onCompleted: (data) => {
      const { deleteMedias } = data || {}
      if (deleteMedias > 0) {
        refetchMedias()
      }
    }
  })
  const [upMediaOrder, { loading: upLoading }] = useMutation(UP_MEDIA_ORDER, {
    onCompleted: (data) => {
      const { upMediaOrder } = data || {}
      if (upMediaOrder) {
        addSuccessSnackbar('Ordre modifié avec succès')
        refetchMedias()
      }
    }
  })
  const [downMediaOrder, { loading: downLoading }] = useMutation(DOWN_MEDIA_ORDER, {
    onCompleted: (data) => {
      const { downMediaOrder } = data || {}
      if (downMediaOrder) {
        addSuccessSnackbar('Ordre modifié avec succès')
        refetchMedias()
      }
    }
  })

  const [open, setOpen] = useState(false)
  const [editMedia, setEditMedia] = useState(false)

  const handleRowClick = (userObj) => {
    setEditMedia(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditMedia(false)
  }

  const mediaFormRef = useRef()

  const handleSave = () => {
    mediaFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )

  return (
    <>
      <MediaTable handleRowClick={handleRowClick} deleteMedias={deleteMedias} upMediaOrder={(id) => upMediaOrder({ variables: { id: parseInt(id) } })} downMediaOrder={(id) => downMediaOrder({ variables: { id: parseInt(id) } })} loading={userLoading || mediaLoading || tvLoading || deleteLoading || upLoading || downLoading || documentLoading || surveyLoading} rows={listMedias?.listAllMedias || []} />
      <CustomModal open={open} handleClose={handleClose} actions={actions} title={editMedia ? "Edition d'un média" : "Ajout d'un média"}>
        <MediaForm ref={mediaFormRef} listDocuments={listDocuments?.listAllDocuments || []} listSurveys={listSurveys?.listAllSurveys || []} listUsers={listUsers?.listUsers || []} listTVs={(listTVs || [])[authState.role === ENUM_USER_ROLE.ADMIN ? 'listAllTVs' : 'listUserTVs']} author={editMedia?.author?.company} owner={editMedia?.owner?.company} listMedias={listMedias?.listAllMedias || []} initMedia={editMedia} handleClose={handleClose} refetchMedias={refetchMedias} />
      </CustomModal>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Ajouter</Button>
      <Button
        style={{ marginLeft: '16px', textDecoration: 'none' }}
        variant="contained"
        color="primary"
        component={Link}
        href={"/display"}
        target={"_blank"}
        endIcon={<Icon>launch</Icon>}
      >
        Aperçu
      </Button>
    </>
  )
}