import { gql } from '@apollo/client'

export const DELETE_MEDIAS = gql`
  mutation deleteMedias($ids: [Int]) {
    deleteMedias(ids: $ids)
  }
`

export const EDIT_MEDIA = gql`
  mutation editMedia($input: MediaInput) {
    editMedia(input: $input) {
      id
      url
      # filename
      # mimetype
      # encoding
      # id
      # name
      # type
      # duration
      # start_date
      # end_date
      # author {
      #   company
      # }
    }
  }
`

export const UP_MEDIA_ORDER = gql`
  mutation upMediaOrder($id: Int) {
    upMediaOrder(id: $id)
  }
`

export const DOWN_MEDIA_ORDER = gql`
  mutation downMediaOrder($id: Int) {
    downMediaOrder(id: $id)
  }
`