import { gql } from '@apollo/client'

export const LIST_ALL_DOCUMENTS = gql`
  query {
    listAllDocuments {
      id
      name
      filename
      start_date
      end_date
      url
      owner {
        id
        company
      }
      author {
        id
        company
      }
    }
  }
`