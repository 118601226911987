import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import AppShell from './AppShell'
import themeContent from './theme'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import Dashboard from './pages/Dashboard'
import Users from './pages/Users'
import TVs from './pages/TVs'
import Medias from './pages/Medias'
import Documents from './pages/Documents'
import Login from './pages/Login'
import Surveys from './pages/Surveys'
import { useContext } from 'react'
import { AuthContext } from './AuthContext'
import DisplayTV from './pages/DisplayTV'
import Logout from './components/Logout'
import { ENUM_USER_ROLE } from './graphql/user'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import WeatherWidget from './widgets/WeatherWidget'
import Cities from './pages/Cities'
import { loadCSS } from 'fg-loadcss'
import React, { useEffect } from 'react'
import Jobs from './pages/Jobs'
import ResetPasswordRequest from './pages/ResetPasswordRequest'
import ResetPassword from './pages/ResetPassword'
import UserProfile from './pages/UserProfile'
import PublicSurvey from './pages/PublicSurvey'

const CustomRoute = ({ authenticatedRoute, children, title, roles, ...props }) => {
  const { isAuthenticated, authState } = useContext(AuthContext)
  document.title = title ? `${title} - Totem vidéo` : document.title
  return (
    <Route {...props}>
      {(!authenticatedRoute || isAuthenticated())
        ? (!roles || roles?.includes(authState.role))
          ? children
          : <Redirect to="/" />
        : <Redirect to="/login" />}
    </Route>
  )
}

function App() {
  const { isAuthenticated, authState } = useContext(AuthContext)

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, [])

  return (
    <MuiThemeProvider theme={createMuiTheme(themeContent)}>
      <Router>
        <Switch>
          <CustomRoute title="Affichage totem" roles={[ENUM_USER_ROLE.TV, ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]} authenticatedRoute path="/display">
            <DisplayTV />
          </CustomRoute>
          <CustomRoute
            title="Widget"
            roles={[ENUM_USER_ROLE.TV, ENUM_USER_ROLE.ADMIN]}
            authenticatedRoute
            path="/widget"
          >
            <WeatherWidget displayed={true} />
          </CustomRoute>
          <CustomRoute title="Déconnexion" exact authenticatedRoute path="/logout">
            <Logout />
          </CustomRoute>
          <CustomRoute
            title="Sondage"
            path="/public/sondages/:slug"
          >
            {({ match }) => (
              <PublicSurvey slug={match.params?.slug} />
            )}
          </CustomRoute>
          <Route path="/">
            <AppShell>
              <Switch>
                <CustomRoute
                  title="Utilisateurs"
                  roles={[ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/users"
                >
                  <Users />
                </CustomRoute>
                <CustomRoute
                  title="Mon profil"
                  roles={[ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/profile"
                >
                  <UserProfile />
                </CustomRoute>
                <CustomRoute
                  title="Totems"
                  roles={[ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/totems"
                >
                  <TVs />
                </CustomRoute>
                <CustomRoute
                  title="Médias"
                  roles={[ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/medias"
                >
                  <Medias />
                </CustomRoute>
                <CustomRoute
                  title="Sondages"
                  roles={[ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/sondages"
                >
                  <Surveys />
                </CustomRoute>
                <CustomRoute
                  title="Localisations"
                  roles={[ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/cities"
                >
                  <Cities />
                </CustomRoute>
                <CustomRoute
                  title="Automatisation"
                  roles={[ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/jobs"
                >
                  <Jobs />
                </CustomRoute>
                <CustomRoute
                  title="Documents"
                  roles={[ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]}
                  authenticatedRoute
                  path="/document"
                >
                  <Documents />
                </CustomRoute>
                <CustomRoute title="Connexion" path="/login">
                  {isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <div style={{ display: 'flex', height: '100%' }}>
                      <Login />
                    </div>
                  )}
                </CustomRoute>
                <CustomRoute title="Mot de passe oublié" path="/reset-password/request">
                  {isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <div style={{ display: 'flex', height: '100%' }}>
                      <ResetPasswordRequest />
                    </div>
                  )}
                </CustomRoute>
                <CustomRoute title="Réinitialisation du mot de passe" path="/reset-password/:token">
                  {isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <div style={{ display: 'flex', height: '100%' }}>
                      <ResetPassword />
                    </div>
                  )}
                </CustomRoute>
                <CustomRoute
                  title="Tableau de bord"
                  authenticatedRoute
                  roles={[ENUM_USER_ROLE.TV, ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN]}
                  path="/"
                >
                  {authState?.role === ENUM_USER_ROLE.TV ? (
                    <Redirect to="/display" />
                  )
                    : (
                      <Dashboard />
                    )}
                </CustomRoute>
              </Switch>
            </AppShell>
          </Route>
        </Switch>
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </MuiThemeProvider>
  );
}

export default App;
