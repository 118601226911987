import React, { useEffect, useReducer } from 'react'
import { Grid, TextField, makeStyles, IconButton, Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/client'
import { RESET_PASSWORD } from '../graphql/user'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useHistory, useParams } from 'react-router'
import useSnackbar from '../hooks/useSnackar'

const initState = {
  reset: {
    password: '',
    confirmPassword: '',
  },
  error: '',
  showPassword: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        reset: {
          ...state.reset,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'TOGGLE_SHOW_PASSWORD':
      return {
        ...state,
        showPassword: !state.showPassword,
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    case 'SET_CONFIRM':
      return {
        ...state,
        confirm: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
  form: {
    maxWidth: '1100px',
  }
}))

export default function ResetPassword() {
  const { token } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const { addSuccessSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data && data?.resetPassword) {
        if (data?.resetPassword === 'ok') {
          addSuccessSnackbar('Votre mot de passe a été réinitialisé avec succès')
          dispatch({ type: 'SET_ERROR', payload: '' })
          history.push('/login')
        } else if (data?.resetPassword === 'expired') {
          dispatch({ type: 'SET_ERROR', payload: 'Le lien de réinitialisation est expiré, merci de recommencer la procédure depuis le début.' })
        } else if (data?.resetPassword === 'token') {
          dispatch({ type: 'SET_ERROR', payload: 'Le lien de réinitialisation est invalide, merci de recommencer la procédure depuis le début.' })
        }
      } else {
        dispatch({ type: 'SET_ERROR', payload: "Un problème est survenu durant l'envoi du mail, merci d'essayer à nouveau ou de contacter un administrateur." })
      }
    }
  })
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  const handleClickShowPassword = () => {
    dispatch({ type: 'TOGGLE_SHOW_PASSWORD' })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleReset = () => {
    resetPassword({ variables: { token, password: state.reset?.password } })
  }
  useEffect(() => {
    if (!token) {
      dispatch({ type: 'SET_ERROR', payload: 'Le token est nécessaire pour réinitialiser votre mot de passe.' })
    } else {
      dispatch({ type: 'SET_ERROR', payload: '' })
    }
  }, [token])
  return (
    <Grid container align="center" justify="center">
      <Grid container spacing={2} alignContent="center" className={classes.form}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Administration des totems VegaCE
          </Typography>
          <Typography variant="h4">
            Réinitialisation du mot de passe
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="password"
            label={"Mot de passe"}
            type={state.showPassword ? 'text' : 'password'}
            variant="outlined"
            autoComplete="off"
            value={state.reset?.password}
            InputProps={{
              endAdornment: (
                <IconButton
                  size='small'
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="confirmPassword"
            label={"Confirmation du mot de passe"}
            type={state.showPassword ? 'text' : 'password'}
            variant="outlined"
            autoComplete="off"
            value={state.reset?.confirmPassword}
            InputProps={{
              endAdornment: (
                <IconButton
                  size='small'
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
            error={state.reset?.password !== state.reset?.confirmPassword}
            helperText={state.reset?.password !== state.reset?.confirmPassword ? 'Les mots de passe doivent être identiques' : ''}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" disabled={loading || state.reset?.password !== state.reset?.confirmPassword} color="primary" onClick={handleReset}>{loading ? 'Chargement...' : 'Réinitialiser le mot de passe'}</Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" onClick={() => history.push('/login')} disabled={loading}>{loading ? 'Chargement...' : 'Connexion'}</Button>
        </Grid>
        {state.error && (
          <Grid item xs={12}>
            <Alert severity="error">{state.error}</Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}