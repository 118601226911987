import { useMutation } from '@apollo/client'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useReducer } from 'react'
import { EDIT_CITY } from '../graphql/city'
import useSnackbar from '../hooks/useSnackar'

const initState = {
  city: {
    name: '',
    longitude: '0',
    latitude: '0',
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_CITY':
      const { __typename, ...restCity } = action.payload
      return {
        ...state,
        city: {
          ...state.city,
          ...restCity,
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        city: {
          ...state.city,
          [action.payload?.field]: action.payload?.value,
        },
      }
    default:
      throw new Error('Unhandled action type')
  }
}

function isFloat(val) {
  const floatRegex = /^-?\d+(?:[.]\d*?)?$/
  if (!floatRegex.test(val))
    return false;

  val = parseFloat(val);
  if (isNaN(val))
    return false;
  return true;
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
}))

const { useImperativeHandle } = React

const CityForm = React.forwardRef(({ initCity, handleClose, refetchCities }, ref) => {
  const classes = useStyles()
  const { addSuccessSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const [editCity, { loading: editLoading }] = useMutation(EDIT_CITY)
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  useImperativeHandle(ref, () => ({
    async handleSave() {
      await editCity({
        variables: {
          input: {
            ...state?.city,
            coordinates: undefined,
          }
        }
      })
      await refetchCities()
      addSuccessSnackbar('Enregistré avec succès')
      if (!state?.city?.id) {
        handleClose()
      }
    }
  }))
  useEffect(() => {
    if (initCity && initCity.id) {
      dispatch({ type: 'INIT_CITY', payload: { ...initCity, latitude: initCity?.coordinates?.latitude, longitude: initCity?.coordinates?.longitude } })
    }
  }, [initCity])
  return (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="name"
        label="Nom"
        variant="outlined"
        autoComplete="off"
        value={state.city?.name}
        onChange={handleChange()}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="latitude"
            label="Latitude"
            variant="outlined"
            value={state.city?.latitude}
            error={!isFloat(state.city?.latitude)}
            helperText={!isFloat(state.city?.latitude) ? 'Vous devez rentrer un nombre valide' : ''}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="longitude"
            label="Longitude"
            variant="outlined"
            value={state.city?.longitude}
            error={!isFloat(state.city?.longitude)}
            helperText={!isFloat(state.city?.longitude) ? 'Vous devez rentrer un nombre valide' : ''}
            onChange={handleChange()}
          />
        </Grid>
      </Grid>
    </>
  )
})

export default CityForm