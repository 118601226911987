import { gql } from '@apollo/client'

export const DELETE_SURVEYS = gql`
  mutation deleteSurveys($ids: [Int]) {
    deleteSurveys(ids: $ids)
  }
`

export const EDIT_SURVEY = gql`
  mutation editSurvey($input: SurveyInput) {
    editSurvey(input: $input) {
      id
      title
      start_date
      end_date
      uniqueResponse
      questions {
        id
        type
        title
        name
        isRequired
        placeHolder
        order
        colCount
        choicesOrder
        hasOther
        otherText
        maxSelectedChoices
        choices
        hasNone
        hasSelectAll
        maxSelectedChoices
        maximumRateDescription
        minRateDescription
      }
      owner {
        id
        company
      }
      author {
        id
        company
      }
    }
  }
`

export const ANSWER_SURVEY = gql`
  mutation answerSurvey($input: SurveyAnswerInput) {
    answerSurvey(input: $input)
  }
`

export const EXPORT_SURVEY = gql`
  mutation exportSurvey($surveyId: Int) {
    exportSurvey(surveyId: $surveyId) {
      title
      company
      data
    }
  }
`