import { gql } from '@apollo/client'

export const LIST_ALL_MEDIAS = gql`
  query {
    listAllMedias {
      id
      name
      type
      duration
      start_date
      end_date
      url
      widgetName
      order
      owner {
        id
        company
      }
      author {
        id
        company
      }
      TVs {
        id
      }
      qrCodeType
      qrCodeLink
      qrCodeText
      qrCodeDocument {
        id
        name
        url
      }
    }
  }
`

export const LIST_TV_MEDIAS = gql`
  query listTVMedias($tvId: Int) {
    listTVMedias(tvId: $tvId) {
      id
      name
      type
      duration
      start_date
      end_date
      url
      widgetName
      order
      author {
        company
      }
      qrCodeType
      qrCodeLink
      qrCodeText
      qrCodeDocument {
        id
        name
        url
      }
      qrCodeSurvey {
        id
        slug
      }
    }
  }
`