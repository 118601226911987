import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, IconButton, DialogTitle as MuiDialogTitle, Typography } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton role="modal-close" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function CustomModal({ open, handleClose, title, actions, children }) {
  return (
    <div>
      <Dialog onClose={handleClose} fullWidth aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle role="modal-title" id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent role="modal-content" dividers>
          {children}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </div>
  )
}
