import React, { createContext, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'
const AuthContext = createContext()
const { Provider } = AuthContext

const AuthProvider = ({ children }) => {
  const client = useApolloClient()
  const cookies = new Cookies()
  const token = localStorage.getItem('token')
  if (token) {
    cookies.set('token', token, { path: '/' })
  }

  const [authState, setAuthState] = useState({
    token,
    ...(token ? jwt_decode(token) : {}),
  })

  const setAuthInfo = (authInfo) => {
    setAuthState({
      ...authInfo,
    })
  }

  const updateAuthInfo = (authInfo) => {
    setAuthInfo({
      ...authState,
      ...authInfo,
    })
  }

  const logout = () => {
    try {
      localStorage.removeItem('token')
      setAuthState({})
      client.resetStore()
      return true
    } catch (e) {
      return false
    }
  }

  const isAuthenticated = () => {
    return authState.token && authState.id
  }

  const loginCallback = (token) => {
    localStorage.setItem('token', token)
    cookies.set('token', token, { path: '/' })
    const decoded = token ? jwt_decode(token) : {}
    updateAuthInfo(decoded)
    return decoded
  }

  return (
    <Provider
      value={{
        authState,
        updateAuthInfo,
        logout,
        isAuthenticated,
        loginCallback,
      }}
    >
      {children}
    </Provider>
  )
}

export { AuthContext, AuthProvider }
