import { useMutation } from '@apollo/client'
import { Button, FormControlLabel, Grid, makeStyles, TextField, Typography, FormControl, Icon, IconButton, InputLabel, MenuItem, Select, Switch } from '@material-ui/core'
import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { EDIT_SURVEY, ENUM_SURVEY_CHOICES_ORDER, ENUM_SURVEY_QUESTION_INPUT_TYPE, ENUM_SURVEY_QUESTION_TYPE } from '../graphql/survey'
import moment from 'moment'
import { AuthContext } from '../AuthContext'
import { Alert } from '@material-ui/lab'
import { ENUM_USER_ROLE } from '../graphql/user'
import useSnackbar from '../hooks/useSnackar'
import slugify from 'slugify'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import { Model, StylesManager, Survey } from 'surveyjs-react'

const sortByOrder = (a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0
const CHOICES_SEPARATOR = ';'
const OTHER_DEFAULT_TEXT = 'Autre (décrivez)'

function trimTrailingChars(s, charToTrim) {
  var regExp = new RegExp(charToTrim + "+$");
  var result = s.replace(regExp, "");

  return result;
}

const initState = {
  survey: {
    title: '',
    start_date: moment(),
    end_date: moment(),
    ownerId: null,
    questions: [],
    uniqueResponse: true,
  },
  error: null,
}

const reorder = (tab) => {
  return tab?.sort(sortByOrder)?.map((item, index) => ({ ...item, order: index + 1 }))
}

const globalQuestionInitType = {
  title: '',
  name: '',
  isRequired: false,
  autoComplete: null,
}

const questionsTypeLabelMapping = {
  [ENUM_SURVEY_QUESTION_TYPE.text]: 'Texte simple',
  [ENUM_SURVEY_QUESTION_TYPE.radiogroup]: 'Choix unique',
  [ENUM_SURVEY_QUESTION_TYPE.dropdown]: 'Sélecteur',
  [ENUM_SURVEY_QUESTION_TYPE.checkbox]: 'Choix multiple',
  [ENUM_SURVEY_QUESTION_TYPE.rating]: 'Evaluation',
}

const questionsInputTypeLabelMapping = {
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.color]: 'Couleur',
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.date]: 'Date',
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.email]: 'Email',
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.number]: 'Nombre',
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.password]: 'Mot de passe',
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.text]: 'Texte',
  [ENUM_SURVEY_QUESTION_INPUT_TYPE.time]: 'Heure',
}

const questionsInitTypeMapping = {
  [ENUM_SURVEY_QUESTION_TYPE.text]: {
    ...globalQuestionInitType,
    inputType: ENUM_SURVEY_QUESTION_INPUT_TYPE.text,
    placeHolder: '',
    type: ENUM_SURVEY_QUESTION_TYPE.text,
  },
  [ENUM_SURVEY_QUESTION_TYPE.radiogroup]: {
    ...globalQuestionInitType,
    choices: '',
    colCount: 1,
    choicesOrder: 'none',
    hasOther: false,
    otherText: OTHER_DEFAULT_TEXT,
    type: ENUM_SURVEY_QUESTION_TYPE.radiogroup,
  },
  [ENUM_SURVEY_QUESTION_TYPE.dropdown]: {
    ...globalQuestionInitType,
    choices: '',
    choicesOrder: 'none',
    hasOther: false,
    otherText: OTHER_DEFAULT_TEXT,
    type: ENUM_SURVEY_QUESTION_TYPE.dropdown,
  },
  [ENUM_SURVEY_QUESTION_TYPE.checkbox]: {
    ...globalQuestionInitType,
    choices: '',
    choicesOrder: 'none',
    colCount: 1,
    hasNone: false,
    hasOther: false,
    hasSelectAll: false,
    maxSelectedChoices: 0,
    otherText: OTHER_DEFAULT_TEXT,
    type: ENUM_SURVEY_QUESTION_TYPE.checkbox,
  },
  [ENUM_SURVEY_QUESTION_TYPE.rating]: {
    ...globalQuestionInitType,
    maximumRateDescription: 'Complètement satisfait',
    minRateDescription: 'Pas satisfait',
    type: ENUM_SURVEY_QUESTION_TYPE.rating,
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_SURVEY':
      const { __typename, author, ...restSurvey } = action.payload
      return {
        ...state,
        survey: {
          ...state.survey,
          ...restSurvey,
          questions: restSurvey?.questions?.map(({ __typename, ...item }) => item) || [],
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        survey: {
          ...state.survey,
          end_date: action.payload?.field === 'start_date' && moment(action.payload?.value).isAfter(moment(state.survey?.end_date || moment())) ? null : state.survey?.end_date,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'ADD_QUESTION':
      if (!action?.payload?.type) {
        throw new Error('Type payload is required')
      }
      return {
        ...state,
        survey: {
          ...state.survey,
          questions: reorder([...(state.survey?.questions || []), {
            ...questionsInitTypeMapping[action?.payload?.type],
          }])
        }
      }
    case 'DELETE_QUESTION':
      return {
        ...state,
        survey: {
          ...state.survey,
          questions: reorder(state.survey?.questions.filter((_, index) => index !== action.payload),)
        }
      }
    case 'CHANGE_QUESTION_ORDER':
      return {
        ...state,
        survey: {
          ...state.survey,
          questions: reorder(state.survey?.questions?.sort(sortByOrder).map((item) => {
            let order = item.order
            if (action.payload?.direction === 'up') {
              if (action.payload?.current === item.order) {
                order = order - 1
              } else if (action.payload?.next === item.order) {
                order = order + 1
              }
            } else if (action.payload?.direction === 'down') {
              if (action.payload?.current === item.order) {
                order = order + 1
              } else if (action.payload?.next === item.order) {
                order = order - 1
              }
            }
            return {
              ...item,
              order,
            }
          })),
        }
      }
    case 'EDIT_QUESTION':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      const result = {
        ...state,
        survey: {
          ...state.survey,
          questions: reorder(state.survey?.questions?.map((item, index) => {
            if (index === action.payload?.index) {
              return {
                ...item,
                name: action.payload?.field === 'title' ? slugify(action.payload?.value, { remove: /[*+~.()'"!:?&@]/g, lower: true }) : item.name,
                [action.payload?.field]: action.payload?.value,
              }
            }
            return item
          }))
        },
      }
      return result
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
  formTVElement: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  alert: ({ alertShowed }) => ({
    visibility: alertShowed ? 'visible' : 'hidden',
    width: '100%',
    marginTop: theme.spacing(2),
  }),
}))

const { useImperativeHandle } = React

const MAX_COL = 6

const ChoicesOrder = ({ choicesOrder, classes, handleChange }) => {
  return (
    <FormControl variant="outlined" margin="dense" fullWidth className={classes.formElement}>
      <InputLabel id="select-choices-order">Tri des choix</InputLabel>
      <Select
        labelId="select-choices-order"
        labelWidth={120}
        value={choicesOrder}
        onChange={handleChange()}
        name="choicesOrder"
      >
        {Object.entries(ENUM_SURVEY_CHOICES_ORDER).map(([key, value]) => (
          <MenuItem key={key} value={key}>{value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const ColCount = ({ classes, colCount, handleChange }) => {
  return (
    <TextField className={classes.formElement}
      fullWidth
      margin="dense"
      name="colCount"
      type="number"
      label="Nombre de colonnes"
      variant="outlined"
      autoComplete="off"
      value={colCount >= 0 ? colCount : 1}
      onChange={handleChange()}
      inputProps={{
        min: 0,
        max: MAX_COL,
      }}
      helperText={colCount !== null && colCount !== undefined && colCount >= 0 ? parseInt(colCount) === 0 ? 'Affichage en ligne' : parseInt(colCount) === MAX_COL ? `Affichage sur ${MAX_COL} colonne(s) : il s'agit du maximum` : `Affichage sur ${colCount} colonne(s)` : undefined}
    />
  )
}

const MaxSelectedChoices = ({ classes, handleChange, maxSelectedChoices }) => {
  return (
    <TextField className={classes.formElement}
      fullWidth
      margin="dense"
      name="maxSelectedChoices"
      type="number"
      label="Nombre de choix possibles"
      variant="outlined"
      autoComplete="off"
      value={maxSelectedChoices >= 0 ? maxSelectedChoices : 1}
      onChange={handleChange()}
      inputProps={{
        min: 0,
        max: MAX_COL,
      }}
    // helperText={maxSelectedChoices !== null && maxSelectedChoices !== undefined && maxSelectedChoices >= 0 ? parseInt(maxSelectedChoices) === 0 ? 'Affichage en ligne' : parseInt(maxSelectedChoices) === MAX_COL ? `Affichage sur ${MAX_COL} colonne(s) : il s\'agit du maximum` : `Affichage sur ${maxSelectedChoices} colonne(s)` : undefined}
    />
  )
}

const HasOtherBlock = ({ classes, handleChange, hasOther, otherText }) => {
  return (
    <>
      <FormControlLabel
        className={classes.formElement}
        control={<Switch checked={hasOther || false} color="primary" onChange={handleChange({ isBoolean: true })} name="hasOther" />}
        label={'Ajouter un choix "autre"'}
      />
      {hasOther && (
        <TextField className={classes.formElement}
          fullWidth
          margin="dense"
          name="otherText"
          label='Texte pour le choix "Autre"'
          placeholder={`Exemple : ${OTHER_DEFAULT_TEXT}`}
          variant="outlined"
          autoComplete="off"
          value={otherText || ''}
          onChange={handleChange()}
        />
      )}
    </>
  )
}

const ChoicesBlock = ({ choices, classes, handleChange }) => {
  return (
    <TextField className={classes.formElement}
      fullWidth
      margin="dense"
      name="choices"
      label="Choix possibles"
      variant="outlined"
      autoComplete="off"
      value={choices}
      onChange={handleChange()}
      helperText={`Séparez vos choix par le symbole ${CHOICES_SEPARATOR} sans espace avant ni après`}
    />
  )
}

const QuestionGlobal = ({ children, classes, handleChange, id, index, order, ...props }) => {
  const {
    isRequired,
    title
  } = props
  return (
    <>
      {/**
       * type (choose above, can't be updated, you have to delete the question and add one with the right type instead)
       * isRequired
       * title => name (slugified title)
       */}
      <FormControlLabel
        className={classes.formElement}
        control={<Switch checked={isRequired || false} color="primary" onChange={handleChange({ isBoolean: true })} name="isRequired" />}
        label={'Obligatoire'}
      />
      <TextField className={classes.formElement}
        required
        fullWidth
        margin="dense"
        name="title"
        label="Titre de la question"
        placeholder="Exemple : Renseignez votre email"
        variant="outlined"
        autoComplete="off"
        value={title || ''}
        onChange={handleChange()}
        helperText={`Nom autogénéré : ${slugify(title || '', { remove: /[*+~.()'"!:?&@]/g, lower: true })}`}
      />
      {children}
    </>
  )
}

const QuestionText = (props) => {
  const { classes, inputType, placeHolder, handleChange } = props
  return (
    <>
      <QuestionGlobal {...props} />
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="placeHolder"
        label="Exemple de réponse"
        placeholder="Exemple : example@domain.tld"
        variant="outlined"
        autoComplete="off"
        value={placeHolder || ''}
        onChange={handleChange()}
      />
      <FormControl variant="outlined" margin="dense" fullWidth className={classes.formElement}>
        <InputLabel id="select-input-type">Type de champ</InputLabel>
        <Select
          labelId="select-input-type"
          labelWidth={120}
          value={inputType || ENUM_SURVEY_QUESTION_INPUT_TYPE.text}
          onChange={handleChange()}
          name="inputType"
        >
          {Object.entries(questionsInputTypeLabelMapping).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

const QuestionRadiogroup = (props) => {
  return (
    <>
      <QuestionGlobal {...props} />
      <ChoicesBlock {...props} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ColCount {...props} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChoicesOrder {...props} />
        </Grid>
      </Grid>
      <HasOtherBlock {...props} />
    </>
  )
}

const QuestionDropdown = (props) => {
  return (
    <>
      <QuestionGlobal {...props} />
      <ChoicesBlock {...props} />
      <ChoicesOrder {...props} />
      <HasOtherBlock {...props} />
    </>
  )
}

const QuestionCheckbox = (props) => {
  const {
    classes,
    handleChange,
    hasNone,
    hasSelectAll,
  } = props
  return (
    <>
      <QuestionGlobal {...props} />
      <ChoicesBlock {...props} />
      <MaxSelectedChoices {...props} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ColCount {...props} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChoicesOrder {...props} />
        </Grid>
        {/* TODO fix selectAll and selectNone options and then enable them */}
        <Grid item xs={12} md={6}>
          <FormControlLabel
            className={classes.formElement}
            control={<Switch checked={hasNone || false} color="primary" onChange={handleChange({ isBoolean: true })} name="hasNone" />}
            label={'Ajouter l\'option "Aucun"'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            className={classes.formElement}
            control={<Switch checked={hasSelectAll || false} color="primary" onChange={handleChange({ isBoolean: true })} name="hasSelectAll" />}
            label={'Ajouter l\'option "Tout sélectionner"'}
          />
        </Grid>
      </Grid>
      <HasOtherBlock {...props} />
    </>
  )
}

const QuestionRating = (props) => {
  const {
    classes,
    handleChange,
    maximumRateDescription,
    minRateDescription,
  } = props
  return (
    <>
      <QuestionGlobal {...props} />
      <TextField className={classes.formElement}
        required
        fullWidth
        margin="dense"
        name="minRateDescription"
        label="Description de la note minimum (1)"
        variant="outlined"
        autoComplete="off"
        value={minRateDescription || ''}
        onChange={handleChange()}
      />
      <TextField className={classes.formElement}
        required
        fullWidth
        margin="dense"
        name="maximumRateDescription"
        label="Description de la note maximum (5)"
        variant="outlined"
        autoComplete="off"
        value={maximumRateDescription || ''}
        onChange={handleChange()}
      />
    </>
  )
}

const QuestionTypeComponentMapping = {
  [ENUM_SURVEY_QUESTION_TYPE.text]: QuestionText,
  [ENUM_SURVEY_QUESTION_TYPE.radiogroup]: QuestionRadiogroup,
  [ENUM_SURVEY_QUESTION_TYPE.dropdown]: QuestionDropdown,
  [ENUM_SURVEY_QUESTION_TYPE.checkbox]: QuestionCheckbox,
  [ENUM_SURVEY_QUESTION_TYPE.rating]: QuestionRating,
}

const SurveyForm = React.forwardRef(({ listUsers, initSurvey, handleClose, refetchSurveys, author, owner }, ref) => {
  const { addSuccessSnackbar } = useSnackbar()
  const alertRef = useRef()
  const { authState } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initState, () => ({
    ...initState,
    survey: {
      ...initState.survey,
      ownerId: initSurvey?.owner?.id || authState.id,
      authorId: initSurvey?.author?.id,
    }
  }))
  const classes = useStyles({ alertShowed: state.error })
  const [editSurvey, { loading: editLoading }] = useMutation(EDIT_SURVEY)
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  const handleChangeQuestion = (index) => ({ isBoolean, isStringAsArray, separator } = { isBoolean: false, isStringAsArray: false, separator: ',' }) => ({ target }) => {
    if (isStringAsArray && separator) {
      dispatch({ type: 'EDIT_QUESTION', payload: { index, field: target?.name, value: target?.value?.split(separator) } })
    } else {
      dispatch({ type: 'EDIT_QUESTION', payload: { index, field: target?.name, value: isBoolean ? target?.checked : target?.value } })
    }
  }
  useImperativeHandle(ref, () => ({
    async handleSave() {
      if (!state.survey?.title || !state.survey?.start_date || !state.survey?.end_date) {
        dispatch({ type: 'SET_ERROR', payload: 'Veuillez remplir les champs obligatoires signalés par *' })
        alertRef.current?.scrollIntoView({ behavior: "smooth", block: 'start' })
      } else if (state.survey?.end_date && moment(state.survey?.start_date).isSameOrAfter(moment(state.survey?.end_date))) {
        dispatch({ type: 'SET_ERROR', payload: "La date de fin d'affichage doit être supérieur à la date de début d'affichage" })
        alertRef.current?.scrollIntoView({ behavior: "smooth", block: 'start' })
      } else {
        dispatch({ type: 'SET_ERROR', payload: null })
        await editSurvey({
          variables: {
            input: {
              ...state?.survey,
              owner: undefined,
              authorId: undefined,
              slug: undefined,
              answers: undefined,
              questions: state?.survey?.questions?.map((item) => {
                return {
                  ...item,
                  colCount: item.type === ENUM_SURVEY_QUESTION_TYPE.radiogroup ? parseInt(item.colCount) : item.colCount,
                }
              }) || [],
            },
          }
        })
        addSuccessSnackbar('Enregistré avec succès')
        await refetchSurveys()
        if (!state?.survey?.id) {
          handleClose()
        }
      }
    }
  }))
  useEffect(() => {
    if (initSurvey && initSurvey.id) {
      dispatch({ type: 'INIT_SURVEY', payload: { ...initSurvey } })
    }
  }, [initSurvey])

  const handleChangeQuestionsOrder = (direction, current, next) => {
    dispatch({ type: 'CHANGE_QUESTION_ORDER', payload: { direction, current, next } })
  }
  const handleDeleteQuestion = (index) => {
    dispatch({ type: 'DELETE_QUESTION', payload: index })
  }
  const json = {
    title: 'Coucou', //state?.survey?.title,
    description: 'Blahblahblah',
    questions: state?.survey?.questions?.sort(sortByOrder)?.filter(({ name }) => name)?.map((item) => {
      if ([ENUM_SURVEY_QUESTION_TYPE.radiogroup, ENUM_SURVEY_QUESTION_TYPE.dropdown, ENUM_SURVEY_QUESTION_TYPE.checkbox].includes(item.type)) {
        return {
          ...item,
          // Clear trailing separators or spaces and remove empty choices (if several separators in a row)
          choices: trimTrailingChars(item.choices.trim(), CHOICES_SEPARATOR).split(CHOICES_SEPARATOR)?.map((choice) => choice.trim())?.filter((v) => v !== '')
        }
      }
      if ([ENUM_SURVEY_QUESTION_TYPE.text].includes(item.type)) {
        if (item.inputType === ENUM_SURVEY_QUESTION_INPUT_TYPE.email) {
          return {
            ...item,
            validators: [
              {
                type: 'email',
              },
            ],
          }
        }
      }
      return item
    }) || [],
  }
  StylesManager.applyTheme("modern")
  const survey = new Model(json)
  // survey.mode = 'display'
  survey.locale = 'fr'
  survey
    .onComplete
    .add(function (result) {
      console.log('Done', JSON.stringify(result.data, null, 3))
    });
  return (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      <TextField className={classes.formElement}
        required
        fullWidth
        margin="dense"
        name="title"
        label="Titre"
        variant="outlined"
        autoComplete="off"
        value={state.survey?.title}
        onChange={handleChange()}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="datetime-local"
            label="Début du sondage"
            type="datetime-local"
            variant="outlined"
            margin="dense"
            value={state.survey?.start_date ? moment(state.survey?.start_date).format('YYYY-MM-DD[T]HH:mm') : ''}
            name="start_date"
            onChange={handleChange()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="datetime-local"
            label="Fin du sondage"
            type="datetime-local"
            variant="outlined"
            margin="dense"
            value={state.survey?.end_date ? moment(state.survey?.end_date).format('YYYY-MM-DD[T]HH:mm') : ''}
            name="end_date"
            onChange={handleChange()}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment(state.survey?.start_date).format('YYYY-MM-DD[T]HH:mm'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            className={classes.formElement}
            control={<Switch checked={state.survey?.uniqueResponse} color="primary" onChange={handleChange(true)} name="uniqueResponse" />}
            label={'Limiter à une réponse par appareil'}
          />
        </Grid>
        {authState?.role === ENUM_USER_ROLE.ADMIN && (
          <Grid item xs={12}>
            <FormControl variant="outlined" required fullWidth className={classes.formElement}>
              <InputLabel id="select-owner">Client</InputLabel>
              <Select
                margin="dense"
                labelId="select-owner"
                labelWidth={55}
                value={state.survey?.ownerId}
                onChange={handleChange()}
                name="ownerId"
              >
                {listUsers.map(({ id, company }) => (
                  <MenuItem key={id} value={id}>{company}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      {(authState?.role === ENUM_USER_ROLE.ADMIN && author) && (
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item>
            <Typography>Ce sondage a été créé par : </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 'bold' }}>{author}</Typography>
          </Grid>
          <Grid item>
            <Typography>pour</Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 'bold' }}>{owner}</Typography>
          </Grid>
        </Grid>
      )}
      <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '20px' }}>Liste des questions</Typography>
      {state.survey?.questions?.sort(sortByOrder)?.map((item, index, self) => {
        const Component = QuestionTypeComponentMapping[item.type]
        return Component && (
          <div key={`${index}-${item.id}`} style={{ backgroundColor: index % 2 === 0 ? undefined : 'rgb(244, 244, 244)', padding: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="h6">Question #{index + 1} - {questionsTypeLabelMapping[item.type]}</Typography>
              <IconButton onClick={() => handleChangeQuestionsOrder('up', item.order, item.order - 1)} disabled={item.order <= 1} size="small" style={{ marginLeft: '5px' }}>
                <ExpandLessIcon />
              </IconButton>
              <IconButton onClick={() => handleChangeQuestionsOrder('down', item.order, item.order + 1)} disabled={item.order >= self.length} size="small">
                <ExpandMoreIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteQuestion(index)} size="small">
                <DeleteIcon />
              </IconButton>
            </div>
            <Component classes={classes} index={index} handleChange={handleChangeQuestion(index)} {...item} />
          </div>
        )
      })}
      <Grid container style={{ marginTop: '20px' }} spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<Icon>add</Icon>}
            onClick={() => dispatch({ type: 'ADD_QUESTION', payload: { type: ENUM_SURVEY_QUESTION_TYPE.text } })}
          >
            {questionsTypeLabelMapping[ENUM_SURVEY_QUESTION_TYPE.text]}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<Icon>add</Icon>}
            onClick={() => dispatch({ type: 'ADD_QUESTION', payload: { type: ENUM_SURVEY_QUESTION_TYPE.radiogroup } })}
          >
            {questionsTypeLabelMapping[ENUM_SURVEY_QUESTION_TYPE.radiogroup]}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<Icon>add</Icon>}
            onClick={() => dispatch({ type: 'ADD_QUESTION', payload: { type: ENUM_SURVEY_QUESTION_TYPE.dropdown } })}
          >
            {questionsTypeLabelMapping[ENUM_SURVEY_QUESTION_TYPE.dropdown]}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<Icon>add</Icon>}
            onClick={() => dispatch({ type: 'ADD_QUESTION', payload: { type: ENUM_SURVEY_QUESTION_TYPE.checkbox } })}
          >
            {questionsTypeLabelMapping[ENUM_SURVEY_QUESTION_TYPE.checkbox]}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<Icon>add</Icon>}
            onClick={() => dispatch({ type: 'ADD_QUESTION', payload: { type: ENUM_SURVEY_QUESTION_TYPE.rating } })}
          >
            {questionsTypeLabelMapping[ENUM_SURVEY_QUESTION_TYPE.rating]}
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '20px' }}>Aperçu du sondage</Typography>
      <Survey model={survey} />
      {/* {state.error && ( */}
      <Alert ref={alertRef} className={classes.alert} severity='error'>{state.error}</Alert>
      {/* )} */}
    </>
  )
})

export default SurveyForm