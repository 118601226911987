import { gql } from '@apollo/client'

export const KPIS = gql`
  query {
    kpis {
      name
      value
    }
  }
`
