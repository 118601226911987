import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  divider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    flex: 1,
  },
  hairline: {
    backgroundColor: props => (props.color === 'primary' ? theme.palette.primary.main : props.color) || '#A2A2A2',
    height: '2px',
    flex: 1,
    minWidth: '10px',
  },
  textDivider: {
    marginLeft: '10px',
    marginRight: '10px',
    color: props => props.textColor === 'primary' ? theme.palette.primary.main : props.textColor,
  },
}))

export default function TextDivider(props) {
  const { color, textColor, textVariant, title } = props
  const classes = useStyles({ color, textColor })
  return (
    <div className={classes.divider}>
      <span className={classes.hairline}>&nbsp;</span>
      <Typography variant={textVariant} className={classes.textDivider}>{title}</Typography>
      <span className={classes.hairline}>&nbsp;</span>
    </div>
  )
}
