import React, { useContext, useReducer } from 'react'
import { Grid, TextField, makeStyles, IconButton, Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/client'
import { LOGIN } from '../graphql/user'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { AuthContext } from '../AuthContext'

const initState = {
  login: {
    email: '',
    password: '',
  },
  error: '',
  showPassword: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        login: {
          ...state.login,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'TOGGLE_SHOW_PASSWORD':
      return {
        ...state,
        showPassword: !state.showPassword,
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
  form: {
    maxWidth: '1100px',
  }
}))

export default function Login() {
  const classes = useStyles()
  const { loginCallback } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data && data?.login && !['false', 'locked'].includes(data?.login)) {
        dispatch({ type: 'SET_ERROR', payload: '' })
        loginCallback(data?.login)
        window.location.reload()
      } else {
        dispatch({ type: 'SET_ERROR', payload: data?.login === 'locked' ? 'Votre compte est vérouillé, merci de contacter un administrateur' : 'Identifiants invalides' })
      }
    }
  })
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  const handleClickShowPassword = () => {
    dispatch({ type: 'TOGGLE_SHOW_PASSWORD' })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleLogin = () => {
    login({ variables: { input: state.login } })
  }
  return (
    <Grid container align="center" justify="center">
      <Grid container spacing={2} alignContent="center" className={classes.form}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Administration des totems VegaCE
          </Typography>
          <Typography variant="h4">
            Connexion
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="email"
            label="Email"
            variant="outlined"
            value={state.login?.email}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="password"
            label={"Mot de passe"}
            type={state.showPassword ? 'text' : 'password'}
            variant="outlined"
            autoComplete="off"
            value={state.login?.password}
            InputProps={{
              endAdornment: (
                <IconButton
                  size='small'
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit" onClick={handleLogin} disabled={loading}>{loading ? 'Chargement...' : 'Connexion'}</Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" href={"/reset-password/request"}>Mot de passe oublié ?</Button>
        </Grid>
        {state.error && (
          <Grid item xs={12}>
            <Alert severity="error">{state.error}</Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}