import { gql } from '@apollo/client'

export const GET_WEATHER_WIDGET = gql`
  query {
    getWidgetByName(name: "weather") {
      widget {
        id
        name
        description
        data
        updatedAt
      }
      localisation {
        id
        name
      }
    }
  }
`

export const GET_NOW_PLAYING_WIDGET = gql`
  query {
    getWidgetByName(name: "nowPlaying") {
      widget {
        id
        name
        description
        data
        updatedAt
      }
    }
  }
`

export const GET_UPCOMING_WIDGET = gql`
  query {
    getWidgetByName(name: "upcoming") {
      widget {
        id
        name
        description
        data
        updatedAt
      }
    }
  }
`