import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { ENUM_MEDIA_TYPE, LIST_TV_MEDIAS, ON_MEDIA_CHANGES } from '../graphql/media'
import { ON_REFRESH_TVS, ON_TV_CHANGES } from '../graphql/tv'
import { AuthContext } from '../AuthContext'
import moment from 'moment'
import { Fade, makeStyles } from '@material-ui/core'
import useWindowDimensions from '../hooks/useWindowDimensions'
import WeatherWidget from '../widgets/WeatherWidget'
import CinemaWidget from '../widgets/CinemaWidget'
import QRCodeDisplay from './QRCodeDisplay'

const useStyles = makeStyles((theme) => ({
  carousel: {
    width: '100%',
    height: '101%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    boxSizing: 'border-box',
    position: 'relative',
    '& > .widget': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  media: {
    position: 'absolute',
  },
}))

const imgFetchOptions = (headers) => ({
  method: 'GET',
  headers,
})

const Media = React.forwardRef(({ type, url, ...props }, ref) => {
  const classes = useStyles()
  const { authState } = useContext(AuthContext)
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const [src, setSrc] = useState(null)
  const getImgDimensions = (img) => {
    let ratio = 0
    let maxWidth = windowWidth
    let maxHeight = windowHeight
    let width = img.width
    let height = img.height
    // Check if the current width is larger than the max
    if (width > maxWidth) {
      ratio = maxWidth / width  // get ratio for scaling image
      img.width = maxWidth // Set new width
      img.height = height * ratio  // Scale height based on ratio
      height = height * ratio    // Reset height to match scaled image
      width = width * ratio   // Reset width to match scaled image
    }

    // Check if current height is larger than max
    if (height > maxHeight) {
      ratio = maxHeight / height // get ratio for scaling image
      img.height = maxHeight   // Set new height
      img.width = width * ratio    // Scale width based on ratio
      width = width * ratio  // Reset width to match scaled image
      height = height * ratio    // Reset height to match scaled image
    }
  }
  const getVideoDimensions = (video) => {
    let ratio = 0
    let maxWidth = windowWidth
    let maxHeight = windowHeight
    let width = video.videoWidth
    let height = video.videoHeight
    // Check if the current width is larger than the max
    if (width > maxWidth) {
      ratio = maxWidth / width  // get ratio for scaling image
      video.width = maxWidth // Set new width
      video.height = height * ratio  // Scale height based on ratio
      height = height * ratio    // Reset height to match scaled image
      width = width * ratio   // Reset width to match scaled image
    }

    // Check if current height is larger than max
    if (height > maxHeight) {
      ratio = maxHeight / height // get ratio for scaling image
      video.height = maxHeight   // Set new height
      video.width = width * ratio    // Scale width based on ratio
      width = width * ratio  // Reset width to match scaled image
      height = height * ratio    // Reset height to match scaled image
    }
  }
  useEffect(() => {
    if (url) {
      const headers = new Headers()
      headers.append('authorization', `Bearer ${authState.token}`)
      fetch(new URL(url, `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}`), imgFetchOptions(headers)).then(r => r.blob()).then((blob) => {
        setSrc(URL.createObjectURL(blob))
      })
    }
  }, [url, authState.token])
  return type === 'VIDEO' ? (
    <video ref={ref} muted autoPlay className={classes.media} onLoadedMetadata={({ target }) => getVideoDimensions(target)} src={src} alt={'Média indisponible'} {...props} />
  ) : type === 'IMAGE' ? (
    <img ref={ref} className={classes.media} onLoad={({ target }) => getImgDimensions(target)} src={src} alt={'Média indisponible'} {...props} />
  ) : 'Média indisponible'
})

const ANIM_TIMEOUT = 1000

const widgets = {
  WEATHER: (props) => <WeatherWidget {...props} />,
  CINEMA_NOW_PLAYING: (props) => <CinemaWidget service='nowPlaying' {...props} />,
  CINEMA_UPCOMING: (props) => <CinemaWidget service='upcoming' {...props} />
}

const sortMedias = (a, b) => {
  return parseInt(a.ownerId) > parseInt(b.ownerId) ? 1 : parseInt(a.ownerId) < parseInt(b.ownerId) ? -1 : parseInt(a.order) > parseInt(b.order) ? 1 : parseInt(a.order) < parseInt(b.order) ? -1 : 0
}

export default function DisplayTV() {
  const classes = useStyles()
  const [activeIndex, setActiveIndex] = useState(0)
  const [timeoutId, setTimeoutId] = useState(0)
  const { authState, loginCallback } = useContext(AuthContext)
  const [standBy, setStandBy] = useState(false)
  const [medias, setMedias] = useState([])
  const filterMedias = ({ start_date, end_date }) => moment(start_date).isSameOrBefore(moment()) && (!end_date || moment(end_date).isSameOrAfter(moment()))
  const updateActivity = useCallback((startActivity = null, endActivity = null) => {
    const currentHours = moment().hours()
    const currentMinutes = moment().minutes()
    const current = currentHours + currentMinutes / 60
    const startHours = moment(startActivity || authState.startActivity).hours()
    const startMinutes = moment(startActivity || authState.startActivity).minutes()
    const start = startHours + startMinutes / 60
    const endHours = moment(endActivity || authState.endActivity).hours()
    const endMinutes = moment(endActivity || authState.endActivity).minutes()
    const end = endHours + endMinutes / 60

    if (
      current >= start
      && current <= end
    ) {
      // Activity
      setStandBy(false)
    } else {
      // No Activity
      setStandBy(true)
    }
  }, [authState])
  useEffect(() => {
    updateActivity()
    const intervalId = setInterval(() => {
      updateActivity()
    }, 30000)
    return () => {
      clearInterval(intervalId)
    }
  }, [updateActivity])

  const incrementActiveIndex = useCallback(() => {
    if (activeIndex >= medias.length - 1) {
      setActiveIndex(0)
    } else {
      setActiveIndex(prev => prev + 1)
    }
  }, [activeIndex, medias.length])
  const { refetch } = useQuery(LIST_TV_MEDIAS, {
    variables: {
      tvId: authState?.id,
    },
    onCompleted: (data) => {
      if (data?.listTVMedias) {
        setMedias(data?.listTVMedias)
      }
    }
  })
  useSubscription(ON_MEDIA_CHANGES, {
    variables: {
      tvId: authState?.id,
    },
    onSubscriptionData: (data) => {
      const TVsConcerned = data?.subscriptionData?.data?.onMediaChanges?.TVsConcerned || []
      if (TVsConcerned.includes(`${authState?.id}`)) {
        refetch({
          tvId: authState?.id,
        })?.then(({ data }) => {
          if (data?.listTVMedias) {
            setMedias(data?.listTVMedias)
          }
        })
      }
    }
  })
  useSubscription(ON_REFRESH_TVS, {
    onSubscriptionData: () => {
      window.location.reload()
    }
  })
  useSubscription(ON_TV_CHANGES, {
    variables: {
      tvId: authState?.role === 'TV' ? authState?.id : 0,
    },
    onSubscriptionData: (data) => {
      const tvToken = data?.subscriptionData?.data?.onTVChanges || ''
      const decoded = loginCallback(tvToken)
      updateActivity(decoded?.startActivity, decoded?.endActivity)
    }
  })
  useEffect(() => {
    if (activeIndex >= 0) {
      if (timeoutId) {
        clearTimeout(timeoutId)
        setTimeoutId(0)
      }
      if (medias.length) {
        const currentMedia = medias[activeIndex]
        if (currentMedia) {
          if (moment(currentMedia?.start_date).isSameOrBefore(moment()) && (!currentMedia?.end_date || moment(currentMedia?.end_date).isSameOrAfter(moment()))) {
            setTimeoutId(setTimeout(() => {
              incrementActiveIndex()
            }, currentMedia?.duration * 1000))
          } else if (medias.length > 1) {
            incrementActiveIndex()
          } else {
            setActiveIndex(-1)
            setTimeoutId(setTimeout(() => {
              setActiveIndex(0)
            }, 5000))
          }
        } else {
          incrementActiveIndex()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias, activeIndex])
  return (
    <>
      <div id="carousel" className={classes.carousel}>
        {standBy ? (
          <div></div>
        ) : medias.filter(filterMedias).length ? medias.sort(sortMedias).map(({ id, type, url, widgetName, duration, qrCodeType, qrCodeDocument, qrCodeText, qrCodeLink, qrCodeSurvey }, index) => (
          <Fade key={id} in={activeIndex === index} timeout={ANIM_TIMEOUT}>
            {type === ENUM_MEDIA_TYPE.WIDGET ? widgets[widgetName]({ displayed: activeIndex === index, duration, timeout: ANIM_TIMEOUT }) :
              type === ENUM_MEDIA_TYPE.QRCODE ? <QRCodeDisplay displayed={activeIndex === index} timeout={ANIM_TIMEOUT} text={qrCodeText} type={qrCodeType} qrCodeLink={qrCodeLink} qrCodeDocument={qrCodeDocument} qrCodeSurvey={qrCodeSurvey} /> : (
                <Media ref={(node) => {
                  if (node && type === 'VIDEO' && activeIndex === index) {
                    node.currentTime = 0
                    node.play()
                  }
                }} type={type} url={url} />
              )}
          </Fade>
        )) : (
          <img style={{ maxWidth: '90%' }} src={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}/public/images/vegace.png`} className={classes.wide} alt={'Logo VegaCE'} />
        )}
      </div>
    </>
  )
}