import { toast } from 'react-toastify'

function useSnackbar() {
  const addSuccessSnackbar = (message) => {
    toast.success(message)
  }

  const addErrorSnackbar = (message) => {
    toast.error(message)
  }

  return { addSuccessSnackbar, addErrorSnackbar }
}

export default useSnackbar
