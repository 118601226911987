import React, { useContext } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Icon from '@material-ui/core/Icon'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink as Link } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import TvIcon from '@material-ui/icons/Tv'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import { AuthContext } from './AuthContext'
import { ENUM_USER_ROLE } from './graphql/user'
import RoomIcon from '@material-ui/icons/Room'
import PollIcon from '@material-ui/icons/Poll'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  navActive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& > *': {
      color: theme.palette.primary.contrastText,
    },
    '&:hover > *': {
      color: theme.palette.primary.main,
    },
  }
}))

const menu = [
  {
    icon: <DashboardIcon />,
    text: 'Tableau de bord',
    to: '/',
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <AccountCircleIcon />,
    text: 'Mon profil',
    to: '/profile',
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <TvIcon />,
    text: 'Totems',
    to: '/totems',
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <PermMediaIcon />,
    text: 'Médias',
    to: '/medias',
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <PollIcon />,
    text: 'Sondages',
    to: '/sondages',
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <GroupIcon />,
    text: 'Utilisateurs',
    to: '/users',
    roles: [ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <RoomIcon />,
    text: 'Localisations',
    to: '/cities',
    roles: [ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <Icon style={{ textAlign: 'center' }} className="fas fa-clipboard-list" />,
    text: 'Automatisation',
    to: '/jobs',
    roles: [ENUM_USER_ROLE.ADMIN],
  },
  {
    icon: <Icon style={{ textAlign: 'center' }} className="fas fa-file" />,
    text: 'Documents',
    to: '/document',
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN],
  },
]

export default function AppShell({ children }) {
  const classes = useStyles()
  const { authState, isAuthenticated } = useContext(AuthContext)
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const filterRole = (item) => item.roles.includes(authState?.role)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Totem vidéo
          </Typography>
          <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            {isAuthenticated() ? (
              <>
                <Typography variant="subtitle1" noWrap style={{ marginRight: '16px' }}>
                  Bonjour {authState?.company}
                </Typography>
                <Button variant="contained" component={Link} to="/logout">
                  Déconnexion
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" component={Link} to="/login">
                  Connexion
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.filter(filterRole).map(({ icon, text, to }) => (
            <ListItem button key={text} component={Link} isActive={(_, location) => location?.pathname === to} activeClassName={classes.navActive} to={to}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  )
}
