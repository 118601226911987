import { useMutation } from '@apollo/client'
import {
  Grid,
  makeStyles,
  Link,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import React, { useContext, useEffect, useReducer, useRef } from 'react'
import {
  EDIT_MEDIA,
  ENUM_MEDIA_QRCODE_TYPE,
  ENUM_MEDIA_QRCODE_TYPE_LABEL,
  ENUM_MEDIA_TYPE,
  ENUM_WIDGETS,
} from '../graphql/media'
import { DropzoneArea } from 'material-ui-dropzone'
import moment from 'moment'
import { AuthContext } from '../AuthContext'
import { Alert } from '@material-ui/lab'
import { ENUM_USER_ROLE } from '../graphql/user'
import useSnackbar from '../hooks/useSnackar'

// https://www.apollographql.com/blog/graphql-file-uploads-with-react-hooks-typescript-amazon-s3-tutorial-ef39d21066a2/

const QRCODE_TEXT_MAX_LENGTH = 400

const initState = {
  media: {
    name: '',
    type: ENUM_MEDIA_TYPE.IMAGE,
    duration: 10,
    start_date: moment(),
    end_date: null,
    file: null,
    url: null,
    blob: null,
    ownerId: null,
    widgetName: '',
    qrCodeType: ENUM_MEDIA_QRCODE_TYPE.LINK,
    qrCodeLink: '',
    qrCodeText: '',
    qrCodeDocument: null,
    qrCodeSurvey: null,
  },
  TVs: [],
  error: null,
}

const imgFetchOptions = (headers) => ({
  method: 'GET',
  headers,
})

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_MEDIA':
      const { __typename, author, ...restMedia } = action.payload
      return {
        ...state,
        media: {
          ...state.media,
          ...restMedia,
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        media: {
          ...state.media,
          end_date:
            action.payload?.field === 'start_date' &&
            moment(action.payload?.value).isAfter(moment(state.media?.end_date || moment()))
              ? null
              : state.media?.end_date,
          duration:
            /*action.payload?.field === 'type' && action.payload?.value === ENUM_MEDIA_TYPE.VIDEO
              ? null
              : */state.media.duration || 10,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'EDIT_TVS_LIST':
      return {
        ...state,
        TVs: action.payload || [],
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
  formTVElement: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  alert: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}))

const { useImperativeHandle } = React

const MediaForm = React.forwardRef(
  ({ listDocuments, listSurveys, listTVs, listUsers, initMedia, handleClose, refetchMedias, author, owner }, ref) => {
    const classes = useStyles()
    const { addSuccessSnackbar } = useSnackbar()
    const alertRef = useRef()
    const { authState } = useContext(AuthContext)
    const [state, dispatch] = useReducer(reducer, initState, () => ({
      ...initState,
      media: {
        ...initState.media,
        ownerId: initMedia?.owner?.id || authState.id,
        authorId: initMedia?.author?.id,
        qrCodeDocument: initMedia?.qrCodeDocument?.id,
        qrCodeSurvey: initMedia?.qrCodeSurvey?.id,
      },
    }))
    const [editMedia, { loading: editLoading }] = useMutation(EDIT_MEDIA, {
      onCompleted: (data) => {
        if (data?.editMedia?.url) {
          const headers = new Headers()
          headers.append('authorization', `Bearer ${authState.token}`)
          fetch(
            new URL(data?.editMedia?.url, `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}`),
            imgFetchOptions(headers),
          )
            .then((r) => r.blob())
            .then((blob) => {
              dispatch({ type: 'INIT_MEDIA', payload: { ...data?.editMedia, blob } })
            })
        }
      },
    })
    const handleChange =
      (isBoolean = false) =>
      ({ target }) => {
        dispatch({
          type: 'EDIT_FIELD',
          payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value },
        })
      }
    const handleChangeTV = ({ target }) => {
      const newArray = target?.checked
        ? [...state?.TVs, listTVs.find(({ id }) => id === target?.name)]
        : [...state?.TVs?.filter(({ id }) => id !== target?.name)]
      dispatch({ type: 'EDIT_TVS_LIST', payload: newArray })
    }
    const toggleAllCompanyTVs = (company, addOrRemove) => {
      // If addOrRemove is > 1, then we ADD some TVs to the selection, else we REMOVE some TVs from the selection
      const newArray = addOrRemove
        ? [...state?.TVs, ...listTVs.filter(({ owner }) => company === owner?.company)]
        : [...state?.TVs?.filter(({ owner }) => company !== owner?.company)]
      dispatch({ type: 'EDIT_TVS_LIST', payload: newArray })
    }
    const toggleAllTVs = (addOrRemove) => {
      // If addOrRemove is > 1, then we ADD some TVs to the selection, else we REMOVE some TVs from the selection
      const newArray = addOrRemove ? [...listTVs] : []
      dispatch({ type: 'EDIT_TVS_LIST', payload: newArray })
    }
    useImperativeHandle(ref, () => ({
      async handleSave() {
        if (
          !state.media?.name ||
          !state.media?.start_date ||
          !state.media?.type ||
          (![ENUM_MEDIA_TYPE.WIDGET, ENUM_MEDIA_TYPE.QRCODE].includes(state.media?.type) &&
            !state.media?.file &&
            !state.media?.url)
        ) {
          dispatch({ type: 'SET_ERROR', payload: 'Veuillez remplir les champs obligatoires signalés par *' })
          alertRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else if (state.media?.qrCodeText?.length > QRCODE_TEXT_MAX_LENGTH) {
          dispatch({
            type: 'SET_ERROR',
            payload: `La longueur du texte personnalisé pour le QRCODE doit être inférieure ou égale à ${QRCODE_TEXT_MAX_LENGTH}`,
          })
        } else if (!state.media?.duration/* && state.media?.type !== ENUM_MEDIA_TYPE.VIDEO*/) {
          dispatch({
            type: 'SET_ERROR',
            payload: `La durée d'affichage est obligatoire`,
          })
          alertRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else if (
          state.media?.end_date &&
          moment(state.media?.start_date).isSameOrAfter(moment(state.media?.end_date))
        ) {
          dispatch({
            type: 'SET_ERROR',
            payload: "La date de fin d'affichage doit être supérieur à la date de début d'affichage",
          })
          alertRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else {
          dispatch({ type: 'SET_ERROR', payload: null })
          await editMedia({
            variables: {
              input: {
                ...state?.media,
                duration: parseInt(state?.media?.duration),
                widgetName: state.media.type === ENUM_MEDIA_TYPE.WIDGET ? state.media.widgetName : undefined,
                url: undefined,
                blob: undefined,
                initialFiles: undefined,
                owner: undefined,
                authorId: undefined,
                TVs: state?.TVs.map(({ available, owner, city, medias, __typename, ...restTV }) => restTV),
              },
            },
          })
          addSuccessSnackbar('Enregistré avec succès')
          await refetchMedias()
          if (!state?.media?.id) {
            handleClose()
          }
        }
      },
    }))
    useEffect(() => {
      if (initMedia && initMedia.id) {
        if (initMedia.url) {
          const headers = new Headers()
          headers.append('authorization', `Bearer ${authState.token}`)
          fetch(
            new URL(initMedia.url, `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_BASE_URL}`),
            imgFetchOptions(headers),
          )
            .then((r) => r.blob())
            .then((blob) => {
              dispatch({ type: 'INIT_MEDIA', payload: { ...initMedia, blob } })
            })
        } else {
          dispatch({
            type: 'INIT_MEDIA',
            payload: {
              ...initMedia,
              qrCodeDocument: initMedia?.qrCodeDocument?.id,
              qrCodeSurvey: initMedia?.qrCodeSurvey?.id,
            },
          })
        }
        dispatch({
          type: 'EDIT_TVS_LIST',
          payload:
            listTVs.filter(({ id }) => initMedia.TVs.map(({ id }) => id).find((tvMediaId) => tvMediaId === id)) || [],
        })
      }
    }, [initMedia, listTVs, authState.token])
    const filterOwnerTVs = (item) =>
      `${state?.media?.ownerId}` !== `${authState.id}` ? item.owner?.id === state?.media?.ownerId : true
    const mapListOwners = ({ owner }) => owner?.company
    const filterUnique = (value, index, self) => self.indexOf(value) === index
    const filterOwnerCompany =
      (company) =>
      ({ owner }) =>
        owner?.company === company
    const filterMediaType = ([_, value]) =>
      value !== ENUM_MEDIA_TYPE.WIDGET ? true : authState?.role === ENUM_USER_ROLE.ADMIN
    return (
      <>
        {editLoading && (
          <Typography variant="body2" style={{ fontStyle: 'italic' }}>
            Enregistrement en cours...
          </Typography>
        )}
        <TextField
          className={classes.formElement}
          required
          fullWidth
          margin="dense"
          name="name"
          label="Nom"
          variant="outlined"
          autoComplete="off"
          value={state.media?.name}
          onChange={handleChange()}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl
              variant="outlined"
              margin="dense"
              disabled={state.media?.id && Boolean(state.media?.blob)}
              required
              fullWidth
              className={classes.formElement}
            >
              <InputLabel id="select-type">Type de média</InputLabel>
              <Select
                labelId="select-type"
                labelWidth={120}
                value={state.media?.type}
                onChange={handleChange()}
                name="type"
              >
                {Object.entries(ENUM_MEDIA_TYPE)
                  .filter(filterMediaType)
                  .map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        
          <Grid item xs={12} md={8}>
              <TextField
                className={classes.formElement}
                fullWidth
                
                margin="dense"
                name="duration"
                label="Durée d'affichage"
                variant="outlined"
                autoComplete="off"
                type="number"
                value={state.media?.duration}
                InputProps={{
                  endAdornment: <span>secondes</span>,
                }}
                onChange={handleChange()}
                helperText={'Temps durant lequel sera affiché le média sur la TV'}
              />
            </Grid>
          {state.media?.type === ENUM_MEDIA_TYPE.WIDGET && (
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="dense" required fullWidth className={classes.formElement}>
                <InputLabel id="select-widget-name">Nom du widget</InputLabel>
                <Select
                  labelId="select-widget-name"
                  labelWidth={120}
                  value={state.media?.widgetName}
                  onChange={handleChange()}
                  name="widgetName"
                >
                  {Object.entries(ENUM_WIDGETS).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {state.media?.type === ENUM_MEDIA_TYPE.QRCODE && (
            <>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  name="qrCodeText"
                  label="Texte personnalisé"
                  variant="outlined"
                  autoComplete="off"
                  value={state.media?.qrCodeText}
                  onChange={handleChange()}
                  helperText={`${state.media?.qrCodeText?.length || '0'} sur ${QRCODE_TEXT_MAX_LENGTH} caractères`}
                  error={
                    state.media?.qrCodeText?.length > QRCODE_TEXT_MAX_LENGTH || state.media?.qrCodeText?.length === 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" margin="dense" required fullWidth>
                  <InputLabel id="select-qrcode-type">Type de QR Code</InputLabel>
                  <Select
                    labelId="select-qrcode-type"
                    labelWidth={140}
                    value={state.media?.qrCodeType}
                    onChange={handleChange()}
                    name="qrCodeType"
                  >
                    {Object.entries(ENUM_MEDIA_QRCODE_TYPE).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {ENUM_MEDIA_QRCODE_TYPE_LABEL[value]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {state.media?.qrCodeType === ENUM_MEDIA_QRCODE_TYPE.LINK && (
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    margin="dense"
                    name="qrCodeLink"
                    label="Lien"
                    variant="outlined"
                    autoComplete="off"
                    value={state.media?.qrCodeLink}
                    onChange={handleChange()}
                  />
                </Grid>
              )}
              {state.media?.qrCodeType === ENUM_MEDIA_QRCODE_TYPE.DOCUMENT && (
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" margin="dense" required fullWidth>
                    <InputLabel id="select-qrcode-document">Document</InputLabel>
                    <Select
                      labelId="select-qrcode-document"
                      labelWidth={120}
                      value={state.media?.qrCodeDocument}
                      onChange={handleChange()}
                      name="qrCodeDocument"
                    >
                      {listDocuments?.map(({ id, name, owner: { company } }) => (
                        <MenuItem key={id} value={id}>{`${name}${
                          authState?.role === ENUM_USER_ROLE.ADMIN ? ` (${company})` : ``
                        }`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {state.media?.qrCodeType === ENUM_MEDIA_QRCODE_TYPE.SURVEY && (
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" margin="dense" required fullWidth>
                    <InputLabel id="select-qrcode-survey">Sondage</InputLabel>
                    <Select
                      labelId="select-qrcode-survey"
                      labelWidth={120}
                      value={state.media?.qrCodeSurvey}
                      onChange={handleChange()}
                      name="qrCodeSurvey"
                    >
                      {listSurveys?.map(({ id, title, owner: { company } }) => (
                        <MenuItem key={id} value={id}>{`${title}${
                          authState?.role === ENUM_USER_ROLE.ADMIN ? ` (${company})` : ``
                        }`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="datetime-local"
              label="Début d'affichage"
              type="datetime-local"
              variant="outlined"
              margin="dense"
              value={state.media?.start_date ? moment(state.media?.start_date).format('YYYY-MM-DD[T]HH:mm') : ''}
              name="start_date"
              onChange={handleChange()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="datetime-local"
              label="Fin d'affichage"
              type="datetime-local"
              variant="outlined"
              margin="dense"
              value={state.media?.end_date ? moment(state.media?.end_date).format('YYYY-MM-DD[T]HH:mm') : ''}
              name="end_date"
              onChange={handleChange()}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment(state.media?.start_date).format('YYYY-MM-DD[T]HH:mm'),
              }}
            />
          </Grid>
          {authState?.role === ENUM_USER_ROLE.ADMIN && (
            <Grid item xs={12}>
              <FormControl variant="outlined" required fullWidth className={classes.formElement}>
                <InputLabel id="select-owner">Client</InputLabel>
                <Select
                  margin="dense"
                  labelId="select-owner"
                  labelWidth={55}
                  value={state.media?.ownerId}
                  onChange={handleChange()}
                  name="ownerId"
                >
                  {listUsers.map(({ id, company }) => (
                    <MenuItem key={id} value={id}>
                      {company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {[ENUM_MEDIA_TYPE.VIDEO, ENUM_MEDIA_TYPE.IMAGE].includes(state.media?.type) && (
          <div style={{ margin: '16px auto' }}>
            {state.media?.blob ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {state.media.type === ENUM_MEDIA_TYPE.VIDEO ? (
                  <video
                    controls
                    style={{ maxWidth: '200px', maxHeight: '200px' }}
                    src={URL.createObjectURL(state.media.blob)}
                  />
                ) : state.media.type === ENUM_MEDIA_TYPE.IMAGE ? (
                  <img
                    style={{ maxWidth: '200px', maxHeight: '200px' }}
                    src={URL.createObjectURL(state.media.blob)}
                    alt={state.media?.url}
                  />
                ) : (
                  <Typography variant="subtitle1">Aperçu impossible à afficher</Typography>
                )}
                {`${state.media.authorId}` === `${authState.id}` && (
                  <Button onClick={() => dispatch({ type: 'EDIT_FIELD', payload: { field: 'blob', value: null } })}>
                    Retirer ce média
                  </Button>
                )}
              </div>
            ) : (
              <form encType="multipart/form-data">
                <InputLabel style={{ marginBottom: '16px' }}>Importation du média *</InputLabel>
                <DropzoneArea
                  acceptedFiles={state.media?.type === ENUM_MEDIA_TYPE.VIDEO ? ['video/*'] : ['image/*']}
                  filesLimit={1}
                  dropzoneText={'Faites glisser et déposez une image ici ou cliquez'}
                  onChange={(files) => dispatch({ type: 'EDIT_FIELD', payload: { field: 'file', value: files[0] } })}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Fichier sélectionné"
                  maxFileSize={40000000}
                  getFileLimitExceedMessage={(filesLimit) =>
                    `Nombre maximum de fichiers atteint. Seulement ${filesLimit} autorisé(s)`
                  }
                  getFileAddedMessage={(filename) => `Fichier ${truncate(filename, 15)} ajouté.`}
                  getFileRemovedMessage={(filename) => `Fichier ${truncate(filename, 15)} retiré.`}
                  getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) =>
                    `Fichier ${truncate(rejectedFile.name, 15)} rejeté. Fichiers autorisés : ${acceptedFiles.join(
                      ', ',
                    )}, taille inférieur à ${maxFileSize / 1000000} Mo`
                  }
                  alertSnackbarProps={{
                    autoHideDuration: 5000,
                  }}
                />
              </form>
            )}
          </div>
        )}
        <Typography variant="h6">Sélectionner une ou plusieurs TV(s)</Typography>
        <Link style={{ cursor: 'pointer', color: 'green', margin: '5px' }} onClick={() => toggleAllTVs(1)}>
          Toutes les TVs
        </Link>
        <Link style={{ cursor: 'pointer', color: 'red', margin: '5px' }} onClick={() => toggleAllTVs(0)}>
          Aucune TVs
        </Link>
        {listTVs?.filter(filterOwnerTVs).length ? (
          listTVs
            ?.filter(filterOwnerTVs)
            .map(mapListOwners)
            .filter(filterUnique)
            .map((company, index) => {
              const TVsToDisplay = listTVs?.filter(filterOwnerTVs).filter(filterOwnerCompany(company))
              return (
                <FormControl key={company || index} component="fieldset" className={classes.formTVElement}>
                  <FormLabel component="legend">{company || 'Autres'}</FormLabel>
                  <div style={{ marginTop: '10px' }}>
                    <Link
                      style={{ cursor: 'pointer', color: 'green', margin: '5px' }}
                      onClick={() => toggleAllCompanyTVs(company, 1)}
                    >
                      Toutes les TVs de {company || 'Autres'}
                    </Link>
                    <Link
                      style={{ cursor: 'pointer', color: 'red', margin: '5px' }}
                      onClick={() => toggleAllCompanyTVs(company, 0)}
                    >
                      Aucune TVs de {company || 'Autres'}
                    </Link>
                  </div>
                  <FormGroup>
                    {TVsToDisplay.length ? (
                      TVsToDisplay.map(({ id, name }) => (
                        <FormControlLabel
                          key={id}
                          control={
                            <Checkbox
                              checked={Boolean(state.TVs?.find(({ id: fId }) => fId === id))}
                              onChange={handleChangeTV}
                              name={`${id}`}
                            />
                          }
                          label={name}
                        />
                      ))
                    ) : (
                      <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                        Aucune TV de disponible
                      </Typography>
                    )}
                  </FormGroup>
                </FormControl>
              )
            })
        ) : (
          <Typography variant="body2" style={{ fontStyle: 'italic' }}>
            Aucune TV de disponible
          </Typography>
        )}
        {authState?.role === ENUM_USER_ROLE.ADMIN && author && (
          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            <Grid item>
              <Typography>Ce média a été créé par : </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: 'bold' }}>{author}</Typography>
            </Grid>
            <Grid item>
              <Typography>pour</Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontWeight: 'bold' }}>{owner}</Typography>
            </Grid>
          </Grid>
        )}
        {state.error && (
          <Alert ref={alertRef} className={classes.alert} severity="error">
            {state.error}
          </Alert>
        )}
      </>
    )
  },
)

export default MediaForm
