import React, { useRef, useState } from 'react'
import DocumentTable from '../components/DocumentTable'
import { useQuery, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import DocumentForm from '../forms/DocumentForm'
import { DELETE_DOCUMENTS, LIST_ALL_DOCUMENTS } from '../graphql/document'
import { LIST_USERS } from '../graphql/user'

export default function Documents() {
  const { loading: userLoading, data: listUsers } = useQuery(LIST_USERS)
  const { loading: documentLoading, data: listDocuments, refetch: refetchDocuments } = useQuery(LIST_ALL_DOCUMENTS)
  const [deleteDocuments, { loading: deleteLoading }] = useMutation(DELETE_DOCUMENTS, {
    onCompleted: (data) => {
      const { deleteDocuments } = data || {}
      if (deleteDocuments > 0) {
        refetchDocuments()
      }
    }
  })

  const [open, setOpen] = useState(false)
  const [editDocument, setEditDocument] = useState(false)

  const handleRowClick = (userObj) => {
    setEditDocument(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditDocument(false)
  }

  const documentFormRef = useRef()

  const handleSave = () => {
    documentFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )

  return (
    <>
      <DocumentTable handleRowClick={handleRowClick} deleteDocuments={deleteDocuments} loading={documentLoading || deleteLoading} rows={listDocuments?.listAllDocuments || []} />
      <CustomModal open={open} handleClose={handleClose} actions={actions} title={editDocument ? "Edition d'un document" : "Ajout d'un document"}>
        <DocumentForm ref={documentFormRef} listUsers={listUsers?.listUsers || []} listDocuments={listDocuments?.listAllDocuments || []} owner={editDocument?.owner?.company} initDocument={editDocument} handleClose={handleClose} refetchDocuments={refetchDocuments} />
      </CustomModal>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Ajouter</Button>
    </>
  )
}