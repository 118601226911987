import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../AuthContext'

export default function Logout() {
  const { logout } = useContext(AuthContext)
  useEffect(() => {
    logout()
  }, [logout])
  return (
    <p>Déconnexion en cours...</p>
  )
}