import { gql } from '@apollo/client'

export const DELETE_CITIES = gql`
  mutation deleteCities($ids: [Int]) {
    deleteCities(ids: $ids)
  }
`

export const EDIT_CITY = gql`
  mutation editCity($input: CityInput) {
    editCity(input: $input) {
      id
      name
      coordinates {
        latitude
        longitude
      }
    }
  }
`