import React from 'react'
import { Fade, Typography } from '@material-ui/core'
import QRCode from 'qrcode.react'
import TextDivider from '../components/TextDivider'
import { ENUM_MEDIA_QRCODE_TYPE } from '../graphql/media'

const QR_CODE_WIDTH = 200
const app_url = 'totemvideo.fr'

function QRCodeDisplay({ displayed, timeout, text, type, qrCodeDocument, qrCodeLink, qrCodeSurvey }) {
  const url =
    type === ENUM_MEDIA_QRCODE_TYPE.LINK
      ? qrCodeLink
      : type === ENUM_MEDIA_QRCODE_TYPE.DOCUMENT
      ? `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_URL}${qrCodeDocument?.url}`
      : type === ENUM_MEDIA_QRCODE_TYPE.SURVEY
      ? `${process.env.REACT_APP_PROTOCOL}://${app_url}/public/sondages/${qrCodeSurvey?.slug}`
      : null
  return (
    <Fade in={displayed} timeout={timeout}>
      <div
        className="widget"
        style={{
          display: 'flex',
          color: 'white',
          textAlign: 'center',
          flexDirection: 'column',
          backgroundColor: 'black',
          alignItems: 'center',
          justifyContent: 'center',
          maxHeight: '100%',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
          width: '100%',
          margin: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: '70px',
            width: '100%',
            overflowY: 'hidden',
          }}
        >
          <Typography variant="h3">{text}</Typography>
        </div>
        {url && (
          <div style={{ minHeight: '400px', width: '100%' }}>
            <div
              style={{
                padding: 30,
                margin: '0 auto',
                marginBottom: '30px',
                width: QR_CODE_WIDTH,
                backgroundColor: 'white',
              }}
            >
              <QRCode value={url} size={QR_CODE_WIDTH} />
            </div>
            <div style={{ margin: '30px 30px' }}>
              <TextDivider textVariant="h6" title="Scannez-moi ! Ou rendez-vous sur le lien ci-dessous" />
            </div>
            <Typography variant="subtitle">{url}</Typography>
          </div>
        )}
      </div>
    </Fade>
  )
}

export default QRCodeDisplay
