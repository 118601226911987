import { gql } from '@apollo/client'

export const ON_WEATHER_UPDATE = gql`
  subscription {
    onWeatherUpdate {
      id
      name
      description
      data
    }
  }
`

export const ON_NOW_PLAYING_UPDATE = gql`
  subscription {
    onNowPlayingUpdate {
      id
      name
      description
      data
    }
  }
`

export const ON_UPCOMING_UPDATE = gql`
  subscription {
    onUpcomingUpdate {
      id
      name
      description
      data
    }
  }
`