import { useMutation } from '@apollo/client'
import { FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Switch, FormGroup, FormLabel, Checkbox, Typography, IconButton } from '@material-ui/core'
import React, { useEffect, useReducer } from 'react'
import { EDIT_USER } from '../graphql/user'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { ENUM_USER_ROLE } from '../graphql/user'
import useSnackbar from '../hooks/useSnackar'

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const initState = {
  user: {
    company: '',
    email: '',
    password: '',
    role: ENUM_USER_ROLE.CLIENT,
    locked: false,
    clientVegaCE: true,
    cityId: '',
  },
  showPassword: false,
  TVs: [],
  validators: {
    email: (value) => {
      return value.length < 1 || emailIsValid(value) ? '' : 'Merci de renseigner une adresse mail valide'
    },
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_USER':
      const { __typename, TVs, ...restUser } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          ...restUser,
        },
        TVs,
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'EDIT_TVS_LIST':
      return {
        ...state,
        TVs: action.payload || [],
      }
    case 'TOGGLE_SHOW_PASSWORD':
      return {
        ...state,
        showPassword: !state.showPassword,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
}))

const { useImperativeHandle } = React

const UserForm = React.forwardRef(({ listTVs, initUser, cities, handleClose, refetchTVs, refetchUsers }, ref) => {
  const classes = useStyles()
  const { addErrorSnackbar, addSuccessSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const [editUser, { loading: editLoading }] = useMutation(EDIT_USER)
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  const handleChangeTV = ({ target }) => {
    const newArray = target?.checked ? [...state?.TVs, listTVs.find(({ id }) => id === target?.name)] : [...(state?.TVs?.filter(({ id }) => id !== target?.name))]
    dispatch({ type: 'EDIT_TVS_LIST', payload: newArray })
  }
  useImperativeHandle(ref, () => ({
    async handleSave() {
      await editUser({
        variables: {
          input: {
            user: {
              ...state?.user,
              city: undefined,
            },
            TVs: state?.TVs.map(({ available, owner, __typename, city, ...restTV }) => restTV),
          }
        }
      }).then(async () => {
        await refetchUsers()
        await refetchTVs()
        addSuccessSnackbar('Enregistré avec succès')
      }).catch((error) => {
        addErrorSnackbar('Problème durant l\'enregistrement')
      })
      if (!state?.user?.id) {
        handleClose()
      }
    }
  }))
  useEffect(() => {
    if (initUser && initUser.id) {
      dispatch({ type: 'INIT_USER', payload: { ...initUser, cityId: initUser?.city?.id } })
    }
  }, [initUser])

  const handleClickShowPassword = () => {
    dispatch({ type: 'TOGGLE_SHOW_PASSWORD' })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="company"
        label="Entreprise"
        variant="outlined"
        autoComplete="off"
        value={state.user?.company}
        onChange={handleChange()}
      />
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="email"
        label="Email"
        variant="outlined"
        autoComplete="off"
        value={state.user?.email}
        onChange={handleChange()}
        helperText={state.validators.email(state?.user?.email)}
        error={state.validators.email(state?.user?.email) !== ''}
      />
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="password"
        label={state.user?.id ? "Changer le mot de passe" : "Mot de passe"}
        type={state.showPassword ? 'text' : 'password'}
        variant="outlined"
        autoComplete="off"
        value={state.user?.password}
        InputProps={{
          endAdornment: (
            <IconButton
              size='small'
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )
        }}
        onChange={handleChange()}
      />
      <FormControl margin="dense" variant="outlined" fullWidth required className={classes.formElement}>
        <InputLabel id="select-localisation">Localisation</InputLabel>
        <Select
          labelId="select-localisation"
          labelWidth={100}
          value={`${state.user?.cityId || ''}`}
          onChange={handleChange()}
          name="cityId"
        >
          <MenuItem value={''}>Choisir une localisation</MenuItem>
          {cities.map(({ id, name }) => (
            <MenuItem key={id} value={`${id}`}>{name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" className={classes.formElement}>
            <InputLabel id="select-role">Rôle</InputLabel>
            <Select
              margin="dense"
              labelId="select-role"
              value={state.user?.role}
              onChange={handleChange()}
              label="Rôle"
              name="role"
            >
              {Object.entries(ENUM_USER_ROLE).filter(([key]) => key !== 'TV').map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            className={classes.formElement}
            control={<Switch checked={state.user?.clientVegaCE} color="primary" onChange={handleChange(true)} name="clientVegaCE" />}
            label={'Client VegaCE'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            className={classes.formElement}
            control={<Switch checked={state.user?.locked} color="primary" onChange={handleChange(true)} name="locked" />}
            label={'Bloqué'}
          />
        </Grid>
      </Grid>
      <FormControl component="fieldset" className={classes.formElement}>
        <FormLabel component="legend">Sélectionner une ou plusieurs TV(s)</FormLabel>
        <FormGroup>
          {listTVs?.length ? listTVs?.map(({ id, name, available }) => (
            <FormControlLabel
              key={id}
              control={<Checkbox disabled={!available && !Boolean(state.TVs?.find(({ id: fId }) => fId === id))} checked={Boolean(state.TVs?.find(({ id: fId }) => fId === id))} onChange={handleChangeTV} name={`${id}`} />}
              label={name}
            />
          )) : (
            <Typography variant="body2" style={{ fontStyle: 'italic' }}>Aucune TV de disponible</Typography>
          )}
        </FormGroup>
      </FormControl>
    </>
  )
})

export default UserForm