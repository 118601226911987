import { gql } from '@apollo/client'

export const ON_TV_CHANGES = gql`
  subscription onTVChanges($tvId: Int) {
    onTVChanges(tvId: $tvId)
  }
`
export const ON_REFRESH_TVS = gql`
  subscription {
    onRefreshTVs
  }
`