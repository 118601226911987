import React, { useReducer } from 'react'
import { Grid, TextField, makeStyles, Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/client'
import { SEND_RESET_PASSWORD_REQUEST } from '../graphql/user'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router'

const initState = {
  reset: {
    email: '',
  },
  error: '',
  showPassword: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        reset: {
          ...state.reset,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'TOGGLE_SHOW_PASSWORD':
      return {
        ...state,
        showPassword: !state.showPassword,
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    case 'SET_CONFIRM':
      return {
        ...state,
        confirm: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
  form: {
    maxWidth: '1100px',
  }
}))

export default function ResetPasswordRequest() {
  const classes = useStyles()
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const [sendResetPasswordRequest, { loading }] = useMutation(SEND_RESET_PASSWORD_REQUEST, {
    onCompleted: (data) => {
      if (data && data?.sendResetPasswordRequest !== false) {
        dispatch({ type: 'SET_ERROR', payload: '' })
        dispatch({ type: 'SET_CONFIRM', payload: `Si cette adresse mail est associée à un compte, vous allez recevoir un mail dans les prochaines minutes avec un lien pour réinitialiser votre mot de passe. Ce lien est valide ${process.env.REACT_APP_RESET_LINK_VALIDITY} minutes.` })
      } else {
        dispatch({ type: 'SET_ERROR', payload: "Un problème est survenu durant l'envoi du mail, merci d'essayer à nouveau ou de contacter un administrateur." })
        dispatch({ type: 'SET_CONFIRM', payload: '' })
      }
    }
  })
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  const handleReset = () => {
    sendResetPasswordRequest({ variables: { email: state.reset?.email } })
  }
  return (
    <Grid container align="center" justify="center">
      <Grid container spacing={2} alignContent="center" className={classes.form}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Administration des totems VegaCE
          </Typography>
          <Typography variant="h4">
            Mot de passe oublié
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField className={classes.formElement}
            fullWidth
            margin="dense"
            name="email"
            label="Email"
            variant="outlined"
            value={state.reset?.email}
            onChange={handleChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleReset} disabled={loading}>{loading ? 'Chargement...' : 'Envoyer un lien de réinitialisation du mot de passe'}</Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" onClick={() => history.push('/login')} startIcon={<ArrowBackIcon />} disabled={loading}>{loading ? 'Chargement...' : 'Retour'}</Button>
        </Grid>
        {state.error && (
          <Grid item xs={12}>
            <Alert severity="error">{state.error}</Alert>
          </Grid>
        )}
        {state.confirm && (
          <Grid item xs={12}>
            <Alert severity="info">{state.confirm}</Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}