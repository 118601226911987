import { gql } from '@apollo/client'

export const LIST_ALL_CITIES = gql`
  query {
    listAllCities {
      id
      name
      coordinates {
        latitude
        longitude
      }
    }
  }
`