import { useMutation } from '@apollo/client'
import { FormControlLabel, makeStyles, TextField, Typography, Switch } from '@material-ui/core'
import React, { useEffect, useReducer } from 'react'
import { EDIT_JOB } from '../graphql/job'
import useSnackbar from '../hooks/useSnackar'

const initState = {
  job: {
    name: '',
    description: '',
    enabled: false,
    cron: '* * * * *',
  },
  cronError: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_JOB':
      const { __typename, ...restJob } = action.payload
      return {
        ...state,
        job: {
          ...state.job,
          ...restJob,
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        job: {
          ...state.job,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'CRON_ERROR':
      return {
        ...state,
        cronError: action.payload,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
}))

const { useImperativeHandle } = React

const checkCronExpression = (value) => {
  const splitted = value.split(' ')
  if (splitted.length < 5 || splitted.length > 6) {
    return -1
  }
  for (const part of splitted) {
    if (part?.replace(/-/g, '')?.replace(/,/g, '')?.replace(/[0-9]/g, '')?.replace(/\//g, '')?.replace(/\*/g, '').length) {
      return -2
    }
  }
  return true
}

const JobForm = React.forwardRef(({ initJob, handleClose, refetchJobs }, ref) => {
  const classes = useStyles()
  const { addSuccessSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const [editJob, { loading: editLoading }] = useMutation(EDIT_JOB)
  const handleChange = (isBoolean = false) => ({ target }) => {
    if (target?.name === 'cron') {
      const value = target?.value
      const test = checkCronExpression(value)
      if (test === -2) {
        dispatch({ type: 'CRON_ERROR', payload: 'Un ou des caractères non autorisés ont été détectés, merci de vérifier la syntaxe.' })
      } else if (test === -1) {
        dispatch({ type: 'CRON_ERROR', payload: 'Il semblerait que vous ayez renseigner trop de paramètres, il doit y en avoir entre 5 et 6.' })
      } else {
        dispatch({ type: 'CRON_ERROR', payload: '' })
      }
    }
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  useImperativeHandle(ref, () => ({
    async handleSave() {
      await editJob({
        variables: {
          input: {
            ...state?.job,
            name: initJob.id ? undefined : state?.job?.name,
          }
        }
      })
      await refetchJobs()
      addSuccessSnackbar('Enregistré avec succès')
      if (!state?.job?.id) {
        handleClose()
      }
    }
  }))
  useEffect(() => {
    if (initJob && initJob.id) {
      dispatch({ type: 'INIT_JOB', payload: { ...initJob, latitude: initJob?.coordinates?.latitude, longitude: initJob?.coordinates?.longitude } })
    }
  }, [initJob])
  return (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="name"
        label="Nom"
        variant="outlined"
        autoComplete="off"
        value={state.job?.name}
        onChange={handleChange()}
        disabled={Boolean(initJob.id)}
      />
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="description"
        label="Description"
        variant="outlined"
        autoComplete="off"
        value={state.job?.description}
        onChange={handleChange()}
      />
      <FormControlLabel
        className={classes.formElement}
        control={<Switch checked={state.job?.enabled} color="primary" onChange={handleChange(true)} name="enabled" />}
        label={'Actif'}
      />
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="cron"
        label="Expression CRON"
        error={state.cronError !== ''}
        helperText={state.cronError}
        variant="outlined"
        autoComplete="off"
        value={state.job?.cron}
        onChange={handleChange()}
      />
    </>
  )
})

export default JobForm