import { useMutation, useQuery } from '@apollo/client'
import { makeStyles, Typography, TextField, IconButton, Button } from '@material-ui/core'
import React, { useReducer } from 'react'
import { EDIT_MY_PROFILE, GET_MY_PROFILE } from '../graphql/user'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import useSnackbar from '../hooks/useSnackar'

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const initState = {
  user: {
    email: '',
    password: '',
  },
  showPassword: false,
  validators: {
    email: (value) => {
      return value.length < 1 || emailIsValid(value) ? '' : 'Merci de renseigner une adresse mail valide'
    },
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_USER':
      const { __typename, ...restUser } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          ...restUser,
        },
      }
    case 'EDIT_FIELD':
      if (!action?.payload?.field) {
        throw new Error('Field payload is required')
      }
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload?.field]: action.payload?.value,
        },
      }
    case 'TOGGLE_SHOW_PASSWORD':
      return {
        ...state,
        showPassword: !state.showPassword,
      }
    default:
      throw new Error('Unhandled action type')
  }
}

const useStyles = makeStyles((theme) => ({
  formElement: {
    marginTop: theme.spacing(2),
  },
}))

function UserProfile() {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initState, () => initState)
  const handleChange = (isBoolean = false) => ({ target }) => {
    dispatch({ type: 'EDIT_FIELD', payload: { field: target?.name, value: isBoolean ? target?.checked : target?.value } })
  }
  const handleClickShowPassword = () => {
    dispatch({ type: 'TOGGLE_SHOW_PASSWORD' })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const { addSuccessSnackbar } = useSnackbar()
  const { loading: userLoading } = useQuery(GET_MY_PROFILE, {
    onCompleted: (data) => {
      if (data && data?.getMyProfile) {
        dispatch({ type: 'INIT_USER', payload: { ...data?.getMyProfile } })
      }
    }
  })
  const [editUser, { loading: editLoading }] = useMutation(EDIT_MY_PROFILE, {
    onCompleted: (data) => {
      if (data && data?.editMyProfile) {
        addSuccessSnackbar('Enregistré avec succès')
        dispatch({ type: 'INIT_USER', payload: { ...data?.editMyProfile } })
      }
    }
  })
  return userLoading ? (
    <Typography variant="body2" style={{ fontStyle: 'italic' }}>Chargement...</Typography>
  ) : (
    <>
      {editLoading && (<Typography variant="body2" style={{ fontStyle: 'italic' }}>Enregistrement en cours...</Typography>)}
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="email"
        label="Email"
        variant="outlined"
        autoComplete="off"
        value={state.user?.email}
        onChange={handleChange()}
        helperText={state.validators.email(state?.user?.email)}
        error={state.validators.email(state?.user?.email) !== ''}
      />
      <TextField className={classes.formElement}
        fullWidth
        margin="dense"
        name="password"
        label={state.user?.id ? "Changer le mot de passe" : "Mot de passe"}
        type={state.showPassword ? 'text' : 'password'}
        variant="outlined"
        autoComplete="off"
        value={state.user?.password}
        InputProps={{
          endAdornment: (
            <IconButton
              size='small'
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {state.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )
        }}
        onChange={handleChange()}
      />
      <Button style={{ marginTop: '16px' }} onClick={() => { editUser({ variables: { user: state.user } }) }} variant="contained" color="primary">
        Enregistrer
      </Button>
    </>
  )
}

export default UserProfile