import { gql } from '@apollo/client'

export const LIST_ALL_TVS = gql`
  query {
    listAllTVs {
      name
      ref
      id
      available
      token
      startActivity
      endActivity
      owner {
        id
        company
      }
      city {
        id
        name
      }
      medias {
        id 
        name
        type
        start_date
        end_date
      }
    }
  }
`

export const LIST_USER_TVS = gql`
  query {
    listUserTVs {
      name
      ref
      id
    }
  }
`
