import { useContext, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import SurveyTable from '../components/SurveyTable'
import { useQuery, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import SurveyForm from '../forms/SurveyForm'
import { DELETE_SURVEYS, LIST_ALL_SURVEYS, EXPORT_SURVEY } from '../graphql/survey'
import { LIST_USERS, ENUM_USER_ROLE } from '../graphql/user'
import { AuthContext } from '../AuthContext'
import moment from 'moment'
import { useCSVDownloader } from 'react-papaparse';

export default function Surveys() {
  const { loading: userLoading, data: listUsers } = useQuery(LIST_USERS)
  const { loading: surveyLoading, data: listSurveys, refetch: refetchSurveys } = useQuery(LIST_ALL_SURVEYS)
  const [deleteSurveys, { loading: deleteLoading }] = useMutation(DELETE_SURVEYS, {
    onCompleted: (data) => {
      const { deleteSurveys } = data || {}
      if (deleteSurveys > 0) {
        refetchSurveys()
      }
    },
  })

  const [open, setOpen] = useState(false)
  const [editSurvey, setEditSurvey] = useState(false)
  const [exportSurvey] = useMutation(EXPORT_SURVEY)
  const { authState } = useContext(AuthContext)
  const { CSVDownloader, Type } = useCSVDownloader();
  const csvRef = useRef()

  const handleRowClick = (userObj) => {
    setEditSurvey(userObj)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditSurvey(false)
  }

  const surveyFormRef = useRef()

  const handleSave = () => {
    surveyFormRef.current.handleSave()
  }

  const actions = (
    <Button autoFocus onClick={handleSave} variant="contained" color="primary">
      Enregistrer
    </Button>
  )

  const downloadSurvey = (id) => async () => {
    const { data } = await exportSurvey({ variables: {
      surveyId: parseInt(id),
    }})
    downloadFile(data?.exportSurvey)
  }

  const downloadFile = (data) => {
    let filenameCsv = ''
    {authState?.role === ENUM_USER_ROLE.ADMIN ? (
      filenameCsv = (`${moment().format('YYYY-MM-DD HH:mm:ss')}` + '_export_sondage_' + `${data?.company}`)
    ) : (
      filenameCsv = (`${moment().format('YYYY-MM-DD HH:mm:ss')}` + '_export_sondage')
    )}
    const csv = <CSVDownloader 
      type={Type.Button} 
      bom={true} 
      config={{delimiter:';'}} 
      filename={filenameCsv}
      data={data?.data}
      style={{display:'none'}} 
      className={'csvClass'}
    />
    ReactDOM.render(csv, csvRef.current);
    const csvButton = document.getElementsByClassName('csvClass')[0]
    csvButton.click()
  }

  return (
    <>
      <SurveyTable
        handleRowClick={handleRowClick}
        deleteSurveys={deleteSurveys}
        downloadSurvey={downloadSurvey}
        loading={userLoading || surveyLoading || deleteLoading}
        rows={listSurveys?.listAllSurveys || []}
      />
      <CustomModal
        open={open}
        handleClose={handleClose}
        actions={actions}
        title={editSurvey ? "Edition d'un sondage" : "Ajout d'un sondage"}
      >
        <SurveyForm
          ref={surveyFormRef}
          listUsers={listUsers?.listUsers || []}
          author={editSurvey?.author?.company}
          owner={editSurvey?.owner?.company}
          initSurvey={editSurvey}
          handleClose={handleClose}
          refetchSurveys={refetchSurveys}
        />
      </CustomModal>
      <div ref={csvRef}/>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Ajouter
      </Button>
    </>
  )
}
