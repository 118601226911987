import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Skeleton } from '@material-ui/lab'
import { AuthContext } from '../AuthContext'
import { ENUM_USER_ROLE } from '../graphql/user'
import useSnackbar from '../hooks/useSnackar'

const loadingUsers = [
  {
    id: 1,
    ref: <Skeleton />,
    name: <Skeleton />,
    coordinates: {
      latitude: <Skeleton width={40} />,
      longitude: <Skeleton width={40} />,
    },
  },
  {
    id: 2,
    ref: <Skeleton />,
    name: <Skeleton />,
    coordinates: {
      latitude: <Skeleton width={40} />,
      longitude: <Skeleton width={40} />,
    },
  },
  {
    id: 3,
    ref: <Skeleton />,
    name: <Skeleton />,
    coordinates: {
      latitude: <Skeleton width={40} />,
      longitude: <Skeleton width={40} />,
    },
  },
  {
    id: 4,
    ref: <Skeleton />,
    name: <Skeleton />,
    coordinates: {
      latitude: <Skeleton width={40} />,
      longitude: <Skeleton width={40} />,
    },
  },
  {
    id: 5,
    ref: <Skeleton />,
    name: <Skeleton />,
    coordinates: {
      latitude: <Skeleton width={40} />,
      longitude: <Skeleton width={40} />,
    },
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN], id: 'id', numeric: false, disablePadding: true, label: 'ID'
  },
  { roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN], id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
  { roles: [ENUM_USER_ROLE.CLIENT, ENUM_USER_ROLE.ADMIN], id: 'coordinates', numeric: false, disablePadding: false, label: 'Coordonnées (lat, long)' },
]

const findHeadCellById = (id) => {
  return headCells.find((item) => item.id === id)
}

function EnhancedTableHead(props) {
  const { classes, userRole, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const roleFilter = (item) => item.roles.includes(userRole)

  return (
    <TableHead>
      <TableRow>
        {userRole === ENUM_USER_ROLE.ADMIN && (<TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>)}
        {headCells.filter(roleFilter).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding && userRole === ENUM_USER_ROLE.ADMIN ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected, handleDelete } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} ligne(s) sélectionnée(s)
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Localisations
          </Typography>
        )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function Row(props) {
  const { row, index, userRole, handleSelectClick, handleRowClick, isSelected } = props
  const isItemSelected = isSelected(row.id)
  const labelId = `enhanced-table-checkbox-${index}`
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {userRole === ENUM_USER_ROLE.ADMIN && (<TableCell padding="checkbox" onClick={(event) => handleSelectClick(event, row.id)}>
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>)}
      {findHeadCellById('id')?.roles?.includes(userRole) && (<TableCell component="th" id={labelId} scope="row" padding={userRole === ENUM_USER_ROLE.ADMIN ? 'none' : 'default'} onClick={() => handleRowClick(row)}>
        {row.id}
      </TableCell>)}
      {findHeadCellById('name')?.roles?.includes(userRole) && <TableCell align="left" onClick={() => handleRowClick(row)}>{row.name}</TableCell>}
      {findHeadCellById('coordinates')?.roles?.includes(userRole) && <TableCell align="left" onClick={() => handleRowClick(row)}>{row.coordinates?.latitude}, {row.coordinates?.longitude}</TableCell>}
    </TableRow>
  )
}

export default function CityTable({ rows, deleteCities, loading, handleRowClick }) {
  const classes = useStyles()
  const { addSuccessSnackbar } = useSnackbar()
  const { authState } = useContext(AuthContext)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const emptyRows = rows.length < rowsPerPage ? 0 : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} handleDelete={() => {
          deleteCities({ variables: { ids: selected.map(item => parseInt(item)) } }).then(({ data }) => {
            if (data?.deleteCities > 0) {
              addSuccessSnackbar('Supprimé avec succès')
              setSelected([])
            }
          })
        }} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              userRole={authState.role}
            />
            <TableBody>
              {stableSort(loading ? loadingUsers : rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .length ?
                stableSort(loading ? loadingUsers : rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <Row
                        key={row.id}
                        isSelected={isSelected}
                        handleSelectClick={handleClick}
                        row={row}
                        index={index}
                        handleRowClick={handleRowClick}
                        userRole={authState.role}
                      />
                    )
                  }) : (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>Aucune donnée</TableCell>
                  </TableRow>
                )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`}
          labelRowsPerPage={'Lignes par page :'}
        />
      </Paper>
    </div>
  )
}
