import React from 'react'

const DigitalClock = ({ value, format, locale, hour12 = false, ...props }) => {
  const getCurrentTime = () => {
    const pLocale = locale || []
    const pHour12 = hour12
    let hour, minute, second
    if (format) {
      switch (format.toLowerCase()) {
        case 'hh':
          hour = '2-digit'
          break
        case 'hh-mm':
          hour = '2-digit'
          minute = '2-digit'
          break
        case 'hh-mm-ss':
          hour = '2-digit'
          minute = '2-digit'
          second = '2-digit'
          break
        default:
          hour = '2-digit'
          minute = '2-digit'
          second = '2-digit'
      }
    }
    return value.toLocaleTimeString(pLocale, { 'hour12': pHour12, 'hour': hour, 'minute': minute, 'second': second })
  }

  return (
    <span {...props}>{getCurrentTime()}</span>
  )
}
export default DigitalClock